import React from 'react'
import './Footer.css'
import FooterTextIconBox from '../../UI/atoms/FooterTextIconBox/FooterTextIconBox'
import {RiGroup2Fill} from 'react-icons/ri'
import {RiHeart2Fill} from 'react-icons/ri'
import FooterBanner from '../../UI/molecules/FooterBanner/FooterBanner'
import FooterList from '../../UI/atoms/FooterList/FooterList'
import logo from '../../../image_assets/white-logo.png'
import {RiLinkedinFill} from 'react-icons/ri'
import {RiTwitterFill} from 'react-icons/ri'
import {RiInstagramLine} from 'react-icons/ri'
import {ReactComponent as FooterLeftShapes} from '../../../svg_assets/Footer_Left_Shapes.svg';
import {ReactComponent as FooterTopRightShapes} from '../../../svg_assets/Footer_Top_Right_Shapes.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer_parent'>
      <FooterLeftShapes className = 'footer_left_shapes'/>
      <div className='footer_top_right_shapes'>
      <FooterTopRightShapes className = 'footer_top_right_shape'/>
      </div>
      
     <div className='footer_contents'>
        <h1>Making your life simpler with privacy in mind</h1>
        <div className='text_icon_box_row'>
            <FooterTextIconBox/>
            <FooterTextIconBox 
            icon={<RiGroup2Fill className='icon'/>}
            header = "User-Friendly"
            description='We believe in making life simpler for you, and giving you the most informed decision that AI can deliver'
            />
            <FooterTextIconBox 
            icon={<RiHeart2Fill className='icon'/>}
            header = "Trusted"
            description='Trusted by over 200 Users to deliver simple solutions to busy families. We never sell your data to anyone.'
           
            
            />
        </div>
        <FooterBanner/>
          <div className='footer_lower_row'>
            <div className='left_items'>

            <FooterList 
              list_header = {<img src = {logo} alt = "logo"/>} 
              list_logo = {logo}
              list_items={
              <li className='list_item'>
                  1-Decision Inc. Ⓒ 2023 
                  All Rights Reserved.
              </li>
            }/>
            </div>
            <div className='center_items'>
              <FooterList 
                list_header = "Company"
                list_items={
                  <ul className='footer_list_ul'>
                    <li className='list_item'>
                    <Link to = "https://about.1-decision.com/">
                    About Us
                    </Link>
                    </li>
                    <li className='list_item'>
                    <Link to = "/FAQ">
                    FAQ
                    </Link>
                    
                    </li>
                    
                      <li className='list_item'>
                    <Link to = "https://emailcontact.1-decision.com/">
                    Contact Us
                    </Link>
                    </li>
                    <li className='list_item'>
                    <Link to = "https://blog.1-decision.com/">
                    Blog
                    </Link>
                    </li>
                    <li className='list_item'>
                    <Link to = "https://1-decision.trysaral.com/">
                    Affiliate program
                    </Link>
                    </li>
                  </ul>
                }
                />
                <FooterList 
                list_header = "Products"
                list_items={
                  <ul className='footer_list_ul'>
                    <li className='list_item'>
                    JERA (AI Life Engine)
                    </li>
                  </ul>
                }
                />
                <FooterList 
                list_header = "Follow Us"
                list_items={
                  <ul className='footer_list_ul'>
                    <li className='list_item_icons'>
                    <Link to = "https://www.linkedin.com/company/1-decision/">
                    <RiLinkedinFill className='footer_icon'/>
                    </Link>
                    

                    <Link to = "https://twitter.com/1Decision_">
                    <RiTwitterFill className='footer_icon'/>
                    </Link>                  
         
                    
                    <Link to = "https://www.instagram.com/1_decision_/">
                    <RiInstagramLine className='footer_icon'/>
                    </Link> 
                    </li>
                  </ul>
                }
                />
            </div>
            <div className='right_items'>
            <h1 className='footer_accent_text'>
            Proudly Built in 
Austin, TX
            </h1>
            </div>

          </div> 
        </div>      
    </div>
  )
}

export default Footer
