import React from 'react'
import'./Accordion.css'
import { useState } from 'react'
import { Component, PropTypes } from 'react';
const Accordion = () => {

    const [toggled, setToggled] = useState(null)
    const toggle = (i) =>{
        if (toggled == i) {
            return setToggled(null)
        }

        setToggled(i)
    }

    const data = [
        {
            question: "How do I get started?",
            answer: "To get started, select a plan that suits your needs! Our single program ($9.90 / month) includes a 9-day free trial so that you can test us out! "
        },
        {
            question: "How do I use 1-Decision’s app (JERA)?",
            answer: "We can give you our decision for anything you need help with! 1. Just input your question into the search bar and click return on your keyboard or decide next to the search bar. 2. It can take up to 30 seconds to decide since we are consuming expert reviews from all over the internet to help automate and speed up your decision-making process! 3. If you are unhappy with your result, you can tweak it. For example, specify which platform you would like to use if you asked for a movie with the family!"

            
        },
        {
            question: "What are some tips for using 1-Decision?",
            answer: `Be simple but descriptive. If you want to eat somewhere, input the location area or food type (such as DFW or a specific city within DFW). 

            If you want to decide on a movie, including the platform you prefer, such as Netflix, will help to ensure you aren’t getting one on a platform you don’t pay for. 
            
            If looking for a product, it helps to include key product categories. Ex: Asking for a baby stroller vs. asking for a baby travel stroller vs. a baby travel stroller under $200 will give you different decisions! All will work, but your use case may need a more specific decision if you know it already.`
        },
        {
            question: "Who is this for, and why did you create a 1-Decision?",
            answer: "This is for people who struggle to make decisions due to too many options, spend too much time researching even for small decisions, get easily distracted by social media, or dislike being bombarded with spam and ads online."
        },
        {
            question: "What are the benefits/outcomes of using 1-Decision?",
            answer: "The main two: Lower Stress. Save Time.  "
        },
        {
            question: "Why do you not serve ads or affiliate links?",
            answer: "We believe that YOU shouldn’t be the product sold, but the company's product should be the product being sold. "
        },
        {
            question: "Can I cancel or change my plan?",
            answer: "Yes! Simply go to your portal (must be logged in), and you can cancel or make changes. Choose annual plans to save money with 2 months free. "
        },
        {
            question: "Why do you ask for my credit card upfront?",
            answer: "We require a credit card for a SaaS free trial upfront to verify identity, prevent fraud, enable a seamless transition to a paid subscription, and save time while ensuring security with industry-standard encryption and a transparent cancellation process."
        },
        {
            question: "What if I disagree with a decision?",
            answer: `Our goal at 1-Decision is to automate them, but you are free to accept or refuse. If you disagree with one, you don’t have to do it; simple as that. 

            If you feel you encountered a bug or it isn’t working as it should, please email me at collin@1-Decision.com.`
        },
        {
            question: "Do you plan to improve 1-Decision?",
            answer: "Definitely! We'll share our public roadmap soon, so you can see where we're headed. Come join us in making decision-making easy and stress-free!"
        },
        {
            question: "Have you got more questions? Contact us!",
            answer: "You can email me at collin@1-decision.com "
        },
    ]
  return (
    <div className='accordion_parent'>
        {data.map((item,i) =>(
            <div 
            key = {i}
            className='item' onClick = {() => toggle(i)} style = {{boxShadow: toggled != i ? null:  "0px 5px 35px rgba(255, 52, 52, 0.35)"}}>

            <button className='accordion' >
                <h2>{item.question}</h2>
                <span>{toggled === i ? '-' : '+'}</span>
                
             </button>
             <div className={"panel"} style = {{maxHeight: toggled != i ? null:  "500px"}}>
                <p>
                {item.answer}
                </p>
                
             </div>
            </div>




        ))}
    </div>
  )
}


export default Accordion