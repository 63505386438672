import React, { useContext, useEffect, useRef, useState } from 'react'
import './DecisionSearchBox.css'
import DecisionButton from '../DecisionButton/DecisionButton'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { storeQueryinFirebase } from '../../../../firebaseConfig/firebase'
import Textarea from "react-expanding-textarea";
const DecisionSearchBox = () => {
  const { 
    decSearchQuery, setDecSearchQuery, 
    isDecResultsLoading, setIsDecResultsLoading, 
    isDecDropdownActive, setIsDecDropdownActive, 
    decLoadingMessage, setDecLoadingMessage, 
    decSearchResults, setDecSearchResults, 
    decReferencesList, setDecReferencesList,
    decisionModelMode, setDecisionModelMode,
    user

  } = useContext(GlobalContext);

  let button_link = decSearchQuery ? "/results" : ""

  // setDecisionModelMode("few-answers-model")

  const decisionSearchInputRef = useRef(null);
  const decisionButtonRef = useRef(null);


    let dust = decisionModelMode === "few-answers-model" ?
    /* Few answers model API call */
     {
      fetchData: function (question) {
  
        setIsDecResultsLoading(true);
        setDecLoadingMessage("May have to press enter or return again.");
        setDecSearchResults(null);
        setIsDecDropdownActive(true);

        /* sends POST request */
        fetch(`https://api.1-decision.com/proxyfew?question=${question}`)
        /* Convert the response to JSON format and format the resulting data to the displayAnswer method */
          .then((response) => response.json())
          .then((data) => this.displayAnswer(data))
          .catch((error) => {
            console.log(error);
            setIsDecResultsLoading(null);
            setDecSearchResults("Whoops, something went wrong! Our hamsters are working hard to fix the issue. Please try your decision again!");
          });
      
      },
      /* Processes the API response and updates the appropriate state variables */
      displayAnswer: function (data) {

        /* Extract the run status from the response */
        const { run } = data.run.status;
        /* If the run status is "errored", update the state variables to indicate that no results were found */
        if (run === "errored") {
          setIsDecResultsLoading(false);
          setDecSearchResults("No results found. Please rephrase your search.");
        } else {
          const { answer } = data.run.results[0][0].value;
          const result = answer.split(/\r?\n/);
          
          const str = [];

          /* Adds the references into an array */
          for (let i = 2; i < result.length - 1; i++) {
            let substring = result[i].substring(i < 10 ? 4 : 5);
            // str.push(`<li><a href="${substring}">${substring}</a></li>`);
            str.push(substring);            
          }

  
          setIsDecResultsLoading(false);
          setDecReferencesList(str);
          setDecSearchResults(result[0]);
          setIsDecDropdownActive(true);
          storeQueryinFirebase(user, decSearchQuery, result[0], str, decisionModelMode)
        }
      },
      searchQ: function (question) {
         this.fetchData(question)
      },
    }
    :

    /* ------------------------------------* One answer model API call *------------------------------------------------*/
    {
       fetchData: function (question) {
  
        setIsDecResultsLoading(true);
        setDecLoadingMessage("May have to press enter or return again.");
        setDecSearchResults(null);
        setIsDecDropdownActive(true);

        /* sends POST request */
        fetch(`https://api.1-decision.com/proxyone?question=${question}`)
        /* Convert the response to JSON format and format the resulting data to the displayAnswer method */
          .then((response) => response.json())
          .then((data) => this.displayAnswer(data))
          .catch((error) => {
            console.log(error);
            setIsDecResultsLoading(null);
            setDecSearchResults("Whoops, something went wrong! Our hamsters are working hard to fix the issue. Please try your decision again!");
          });
      
      },
      /* Processes the API response and updates the appropriate state variables */
      displayAnswer: function (data) {
        // console.log(data);
        
        /* Extract the run status from the response */
        const { run } = data.run.status;
        /* If the run status is "errored", update the state variables to indicate that no results were found */
        if (run === "errored") {
          setIsDecResultsLoading(false);
          setDecSearchResults("No results found. Please rephrase your search.");
        } else {
          const answer = data.run.results[0][0].value.message.content;
          const result = answer
  
          setIsDecResultsLoading(false);
          setDecReferencesList(false);
          setDecSearchResults(result);
          setIsDecDropdownActive(true);
          storeQueryinFirebase(user, decSearchQuery, result, null, decisionModelMode)
        }
      },
      searchQ: function (question) {
         this.fetchData(question)
      },
    }

    //Called when the user submits a search query
    const search = () => {
      if (decSearchQuery) {
        let query = decSearchQuery;
        dust.searchQ(query);
        dust.displayAnswer();
      
      } else {
        decisionSearchInputRef.current.setCustomValidity("Please enter a search query.");
        decisionSearchInputRef.current.reportValidity();
      }
    };

      const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          decisionButtonRef.current.click();
        }
      };

  return (
    <div className='expandable_decide_search_box_parent'>
      <div className='expandable_decide_search_box_shape_tl'/>
      <div className='expandable_decide_search_box_shape_br'/>
       <div className='expandable_decide_search_box_background'>
       <div className='expandable_decide_search_box_foreground'>
       <div className='expandable_decide_search_box_foreground_contents'>
        <Textarea  type= "search" className='expandable_decide_search_input textarea' id='decision_search_input'
        ref={decisionSearchInputRef}
         onChange={(e) => setDecSearchQuery(e.target.value)}  onKeyDown={handleEnterKeyPress} value = {decSearchQuery ? decSearchQuery : ""}
         required />
        <DecisionButton button_text='Decide' button_link={button_link} decisionButtonRef={decisionButtonRef}  button_function={() => search()}/>
       </div>
        </div>    
       </div>
    </div>
  )
  }
export default DecisionSearchBox