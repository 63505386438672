import React, { useContext } from 'react'
import './ResultsPage.css'
import NavBar from '../../UI/organisms/NavBar/NavBar'
import DecisionHero from '../../templates/DecisionHero/DecisionHero'
import ResultsHero from '../../templates/ResultsHero/ResultsHero'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../../contexts/GlobalContext'

const ResultsPage = () => {
  const {setNeedsSubscription} = useContext(GlobalContext);
  setNeedsSubscription(true)
  
  return (
    <motion.div className='decision_page_parent'
    initial={{ translateX: 15, opacity: 0 }}
    animate={{ translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: 1 , duration:.5}}
    onLoad={window.localStorage.getItem('1-DECISION-APP') =='false'}
    >
      <Helmet>
        <title>Results | 1-Decision</title>
      </Helmet>
      <NavBar/>
      <ResultsHero/>
    </motion.div>
  )
}

export default ResultsPage