import React, { useContext } from 'react'
import './FaqPage.css'
import FaqHero from '../../templates/FaqHero/FaqHero'
import NavBar from '../../UI/organisms/NavBar/NavBar'
import Footer from '../../templates/Footer/Footer'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../../contexts/GlobalContext'

const FaqPage = () => {
  const {setNeedsSubscription} = useContext(GlobalContext);
  setNeedsSubscription(false)
  return (
    <motion.div className='faq_page_parent'
    
    initial={{ translateX: 15, opacity: 0 }}
    animate={{ translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: 0.6 , duration:.5}}
    
    >
      <Helmet>
        <title> Frequently Asked Questions | 1-Decision</title>
      </Helmet>
    <NavBar/>
    <FaqHero/>
    <Footer/>
    </motion.div>
  )
}

export default FaqPage