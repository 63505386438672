import React, { useContext } from 'react'
import './DecisionSuggestionBox.css'
import { RiSearch2Line } from 'react-icons/ri'
import { GlobalContext } from '../../../../contexts/GlobalContext'
const DecisionSuggestionBox = ({suggestion_text = "Best Tacos in Dallas, TX", suggestion_funtion = ""}) => {

  const { setDecSearchQuery } = useContext(GlobalContext)
  return (
    <div className='decision_suggestion_box_parent' onClick={() => {
      const searchInput = document.querySelector("#decision_search_input")
      searchInput.value = suggestion_text;
      setDecSearchQuery(suggestion_text)
    }}>
      <h2 className='suggestion_text'>{suggestion_text}</h2>
      
      <div className='suggestion_icon_box'>
      <RiSearch2Line/>
      </div>
      </div>
  )
}

export default DecisionSuggestionBox