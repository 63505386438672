import React from 'react'
import './ResultsButton.css'

import { RiSearch2Line } from 'react-icons/ri'
const ResultsButton = ({button_text = "Start Free Trial", button_icon = <RiSearch2Line className='button_icon'/>, button_function = null }) => {
  return (
    <button className= {button_icon != "" ? "results_button" : "results_button_no_icon"} onClick = {button_function}>

        {button_icon}
        {button_text}
    </button>
  )
}

export default ResultsButton