import { useContext } from "react";
import { Navigate } from "react-router-dom"
import { GlobalContext } from "../../../contexts/GlobalContext";

const LocalPage = () => {
  const {setNeedsSubscription} = useContext(GlobalContext);
  setNeedsSubscription(false)
  
    return (
        <Navigate to="/"/>
    )
}

export default LocalPage;