
import '../../../App.css';
import HomeHero from '../../../components/templates/HomeHero/HomeHero';
import NavBar from '../../../components/UI/organisms/NavBar/NavBar';
import StatsSection from '../../../components/templates/StatsSection/StatsSection';
import HomeSubheading from '../../../components/templates/HomeSubheading/HomeSubheading';
import BannerSection from '../../../components/templates/BannerSection/BannerSection';
import SubscriptionSection from '../../../components/templates/SubscriptionSection/SubscriptionSection';
import QuestionsSection from '../../../components/templates/QuestionsSection/QuestionsSection';
import Footer from '../../../components/templates/Footer/Footer';
import './HomePage.css'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

import { Context, useContext, useState } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import HeroLoggedIn from '../../../HeroLoggedIn.js'
import HeroLoggedOut from '../../../HeroLoggedOut.js'
function HomePage() {
  const {user, setUser, isSubscribed, setIsSubscribed, setNeedsSubscription} = useContext(GlobalContext)
  setNeedsSubscription(false)

  return (
    <motion.div className="home_parent_main"
    
    initial={{ translateX: 15, opacity: 0 }}
    animate={{ translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: 0.6 , duration:.5}}
    
    onLoad={window.localStorage.getItem('1-DECISION-APP') =='false'}>
      <Helmet>
        <title>Home | 1-Decision</title>
      </Helmet>
      <NavBar/>
      <HomeHero 
      header_text={ isSubscribed ? HeroLoggedIn[0].header : HeroLoggedOut[0].header} 
      paragraph_text = { isSubscribed ? HeroLoggedIn[0].paragraph : HeroLoggedOut[0].paragraph} 
      accent_arrow_text = { isSubscribed ? HeroLoggedIn[0].arrow_text : HeroLoggedOut[0].arrow_text} 
      button_text = { isSubscribed ? HeroLoggedIn[0].button_text : HeroLoggedOut[0].button_text} 
      log_state = {user}
      subscribed_state = {isSubscribed}

      />
      <StatsSection/>
      <HomeSubheading/>
      <BannerSection/>
      <SubscriptionSection/>
      <QuestionsSection/>
      <Footer/>
    </motion.div>
  );
}

export default HomePage;
