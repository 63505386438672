const suggestions = [
  [
    "Best Sushi in Atlanta, GA",
    "Coolest Playground in NYC",
    "Best Family Resort in Orlando, FL",
    "Best Car Seats for Toddlers",
    "Must-Visit Children's Museums in Washington, D.C.",
    "Top-Rated Zoos in the United States",
    "Most Popular Parenting Books for New Parents",
    "Best Vegetarian Restaurant in Austin, TX",
    "Good Pizza Place in Brooklyn, NY",
    "Best Guitar Under $500",
    "Best New Comedy Movie on Netflix",
  ],
  [
    "Best Motorcycle Under $1000",
    "Best Playground Equipment for Backyards",
    "Best Ice Cream Shops in San Diego, CA",
    "Coolest Water Park in the US",
    "Best Parenting Podcast for Millennial Parents",
    "Good Board Game for Family Game Nights",
    "Great Children's Book",
    "Best Family-Friendly Beach in Miami, FL",
    "Best Meal Delivery Service for Family",
    "Best Noise-Cancelling Headphones",
    "Best 3 Row SUV for Families",
  ],
  [
    "Best Diapers for Newborn",
    "Best Sci-Fi Show on Netflix",
    "Great Hotels in Raleigh, NC",
    "Best Hiking Trail in Chicago, IL",
    "Best Air Purifier",
    "Best Educational App for Kids",
    "Best Tourist Spots in Atlanta, GA",
    "Great Recipe Books for Parents",
    "Best Smart TV",
    "Best DSLR Camera",
    "Best Travel Stroller for twins",
  ],
  [
    "Best Shows for Kids",
    "Best Meditation App",
    "Best Parks in NY",
    "Best Camping Tent for a Family",
    "Best High Chair for Babies",
    "Best Playpen for Babies",
    "Best Laptop for College Student",
    "Best Chair for Home Office",
    "Best Dog Breed for Families",
    "Best Slow Cooker",
    "Best Tacos in Dallas, TX",
  ],
];

export default suggestions;
