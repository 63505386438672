import React from 'react'
import './HowItWorksCardMobile.css'
import {ReactComponent as GlobeSearch} from '../../../../svg_assets/globe_search.svg';
const HowItWorksCardMobile = ({style = "", icon = <GlobeSearch className = "card_icon"/>, default_text = "Our AI consumes the entire internet to provide you the one answer."}) => {
  return (
    <div className={style == '' ? 'hiwcm_main' : style = "hiwcm_main_alternate" }>
        <div className='hiwcm_parent'>
            {icon}
        </div>
        <p className='hiwcm_text'>{default_text}</p>
    </div>
  )
}

export default HowItWorksCardMobile