import logo from "./logo.svg";
import "./App.css";
import HomeHero from "./components/templates/HomeHero/HomeHero";
import NavBar from "./components/UI/organisms/NavBar/NavBar";
import StatsSection from "./components/templates/StatsSection/StatsSection";
import HomeSubheading from "./components/templates/HomeSubheading/HomeSubheading";
import BannerSection from "./components/templates/BannerSection/BannerSection";
import SubscriptionSection from "./components/templates/SubscriptionSection/SubscriptionSection";
import QuestionsSection from "./components/templates/QuestionsSection/QuestionsSection";
import ReactGA from "react-ga";
import Footer from "./components/templates/Footer/Footer";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { useEffect } from "react";
import { firebase } from "./firebaseConfig/firebase";
import { Helmet } from "react-helmet";

function App() {
  //-----------------------For Google Analytics--------------------//
  const TRACKING_ID = "G-Z79SQDFRKJ";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      {/* <Helmet>

    <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z79SQDFRKJ"></script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z79SQDFRKJ"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)}
      gtag('js', new Date());

      gtag('config', 'G-Z79SQDFRKJ');
    </script>  
      
    </Helmet> */}
      <div className="App">
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
}

export default App;
