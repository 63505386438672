import React from 'react'
import './StatsBox.css'
const StatsBox = ({number_value = "21", default_text = "Users Helped"}) => {
  return (
    <div className='stats_box'>
        <h1 className='stats_number'>
        {number_value}
        </h1>
        <h2 className='stats_text'>
            {default_text}
        </h2>
    </div>
  )
}

export default StatsBox