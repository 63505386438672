import React from 'react'
import'./SubscriptionCardMobile.css'
import {ReactComponent as SubscriptionBoxScribble} from '../../../../svg_assets/mobile_subscription_card_scribble.svg';
import MainButton from '../../atoms/MainButton/MainButton'
import blur_triangles from '../../../../image_assets/Blur_Triangles_1.webp' 
import {ReactComponent as FadedMobileParticles} from '../../../../svg_assets/Faded_Mobile_Particles.svg'
import { motion, AnimatePresence } from 'framer-motion';
import { Context, useContext } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext'
import productsMonthly from '../../../../ProductsMonthly';
import productsYearly from '../../../../ProductsYearly'; 
const SubscriptionCardMobile = ({
name = "Lite", 
price = "3.50", 
term = "mo", 
description = "Limited features compared to core plans",
first_detail = "placeholder text",
second_detail = "placeholder text",
third_detail = "placeholder text",
button_text = "Start Free Trial",
button_function = ()=> {console.log("Subscription Button Clicked")},
button_link = "",
animation_array = [0,0,0],
enter_animation_values = {},
animate_animation_values = {},
exit_animation_values = {},
key_value = 2,
product_index = 0,
display = true

}) => {
  const {toggleValue, setToggleValue} = useContext(GlobalContext);
  let exit_data = enter_animation_values.reverse_enter_values
  return (
    <AnimatePresence mode='wait'>
      
      { toggleValue == "monthly"?  (
      <motion.div 
        className={ display == true? 'subscription_card_mobile_parent': "subscription_card_mobile_parent_hide"}
        key = {key_value}
        initial={enter_animation_values}
        animate={animate_animation_values}
        exit={exit_animation_values}
        transition={{ delay: .1, duration: .8 }}
      >
      <FadedMobileParticles className = "faded_mobile_particles"/>
        <div className='subscription_card_mobile_contents'>
            <div className='top_items'>
              <img src = {blur_triangles} className = 'triangle_background'/>
                <h1 className='card_heading'>
                {productsMonthly[product_index].name}
                </h1>
                <h2 className='mobile_card_price'>
                ${productsMonthly[product_index].price}/<span>{"mo"}</span>
                </h2>
                <p className='mobile_plan_description'>
                {productsMonthly[product_index].description}
                </p>

            </div>
            <div className='center_line'>
                <SubscriptionBoxScribble/>
            </div>
            <div className='bottom_items'>
              <ul className='subscription_details_mobile_list'>
              <li>{productsMonthly[product_index].subscriptionDetails[0]}</li>
              <li>{productsMonthly[product_index].subscriptionDetails[1]}</li>
              <li>{productsMonthly[product_index].subscriptionDetails[2]}</li>
              </ul>
              <MainButton button_icon={""} button_text = {button_text} button_function = {button_function}/>
            </div>
            

        </div>
    </motion.div>
      ) 
      
      : 


      <motion.div 
      className={ display == true? 'subscription_card_mobile_parent': "subscription_card_mobile_parent_hide"}
      key = {"mobile_card"}
      initial={enter_animation_values}
      animate={animate_animation_values}
      exit={exit_animation_values}
      transition={{ delay: .1, duration: .8 }}


    >
    <FadedMobileParticles className = "faded_mobile_particles"/>
      <div className='subscription_card_mobile_contents'>
          <div className='top_items'>
            <img src = {blur_triangles} className = 'triangle_background'/>
              <h1 className='card_heading'>
              {productsYearly[product_index].name}
              </h1>
              <h2 className='mobile_card_price'>
              ${productsYearly[product_index].price}/<span>{"yr"}</span>
              </h2>
              <p className='mobile_plan_description'>
              {productsYearly[product_index].description}
              </p>

          </div>
          <div className='center_line'>
              <SubscriptionBoxScribble/>
          </div>
          <div className='bottom_items'>
            <ul className='subscription_details_mobile_list'>
            <li>{productsYearly[product_index].subscriptionDetails[0]}</li>
            <li>{productsYearly[product_index].subscriptionDetails[1]}</li>
            <li>{productsYearly[product_index].subscriptionDetails[2]}</li>
            </ul>
            <MainButton button_icon={""} button_text = {button_text} button_function = {button_function}/>
          </div>
          

      </div>
  </motion.div>
      
      }



    </AnimatePresence>

  )
}

export default SubscriptionCardMobile