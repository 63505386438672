import { firebase } from "../firebaseConfig/firebase";
// import { GlobalContext } from '../contexts/GlobalContext';

// import { useContext } from "react";
//Signs user out of firebase authentication

const handleSignOut = (navigate) => {
  
  firebase
    .auth()
    .signOut()
    .then(() => {
      navigate("/");
    })
    .catch((error) => {
      console.log("Error signing out:", error);
    });
};

export default handleSignOut;
