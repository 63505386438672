import React from 'react'
import './HowItWorksCard.css'
import {ReactComponent as GlobeSearch} from '../../../../svg_assets/globe_search.svg';
const HowItWorksCard = ({icon = <GlobeSearch/>, default_text = "Our AI consumes the entire internet to provide you the one answer."}) => {
  return (
    <div className='hiwc_main'>
        <div className='hiwc_parent'>
            {icon}
        </div>
        <p className='hiwc_text'>{default_text}</p>
    </div>
  )
}

export default HowItWorksCard