import React, { useCallback, useContext, useEffect, useRef } from "react";
import Textarea from "react-expanding-textarea";
import './ExpandableDecisionSearchBox.css'
import DecisionButton from '../DecisionButton/DecisionButton'
import { GlobalContext } from "../../../../contexts/GlobalContext";
const ExpandableDecisionSearchBox = ({default_text = "The best baby stroller is"}) => {
  const { 
    decSearchQuery, setDecSearchQuery, 
    isDecResultsLoading, setIsDecResultsLoading, 
    isDecDropdownActive, setIsDecDropdownActive, 
    decLoadingMessage, setDecLoadingMessage, 
    decSearchResults, setDecSearchResults, 
    decReferencesList, setDecReferencesList 
  } = useContext(GlobalContext);


  const textareaRef = useRef(null);

  const handleChange = useCallback((e) => {
    console.log("Changed value to: ", e.target.value);
  }, []);

  useEffect(() => {
    textareaRef.current.focus();
  }, []);

// console.log(decLoadingMessage)
// console.log(decSearchResults ? decSearchResults : "")

  return (
    <div className='expandable_decision_search_box_parent'>
      <div className='expandable_decision_search_box_shape_tl'/>
      <div className='expandable_decision_search_box_shape_br'/>
       <div className='expandable_decision_search_box_background'>
       <div className='expandable_decision_search_box_foreground'>
       <div className='expandable_decision_search_box_foreground_contents'>
        {/* <p className='default_text'>{default_text}</p> */}
        {/* <textarea defaultValue ={default_text} type= "search" className='expandable_decision_search_input'>
          
        </textarea> */}

        <Textarea
        className="textarea"
        id="my-textarea"
        name="pet[notes]"
        onChange={handleChange}
        // placeholder="Start typing..."
        ref={textareaRef}
        value={decSearchResults ? decSearchResults : "Go to the decision page and enter a decision!"}
        readOnly

      />
       </div>

        </div>    
       </div>
    </div>
  )
}

export default ExpandableDecisionSearchBox