import React from 'react'
import './FooterTextIconBox.css'
import {RiWindyFill} from 'react-icons/ri'
const FooterTextIconBox = ({icon = <RiWindyFill className='icon'/>, header = "Simplicity", description = "We like to make it so that it’s easy & fast to find the right decision for you & your family"}) => {
  return (
    <div className='footer_tib_parent'>
        <div className='top_items'>
        <div className='icon_box'>
            {icon}
        </div>
        <h1 className='icon_box_header'>{header}</h1>
        </div>
        <p className='icon_box_paragraph'>

        {description}
        </p>
    </div>
  )
}

export default FooterTextIconBox