import React from 'react'
import './SubscriptionSection.css'
import TextBox from '../../UI/atoms/TextBox/TextBox'
import PlanToggle from '../../UI/atoms/PlanToggle/PlanToggle'
import SubscriptionCard from '../../UI/molecules/SubscriptionCard/SubscriptionCard'
import SubscriptionCardVariant from '../../UI/molecules/SubscriptionCardVariant/SubscriptionCardVariant'
import SubscriptionToggleText from '../../UI/atoms/SubscriptionToggleText/SubscriptionToggleText'
import SubscriptionCardMobile from '../../UI/molecules/SubscriptionCardMobile/SubscriptionCardMobile'
import SubscriptionCardVariantMobile from '../../UI/molecules/SubscriptionCardVariantMobile/SubscriptionCardVariantMobile'
import productsMonthly from '../../../ProductsMonthly.js';
import productsYearly from '../../../ProductsYearly.js';
import { useContext } from 'react'
import { useState, useEffect } from 'react'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { motion, AnimatePresence } from 'framer-motion';
import data from '../../../data'
import { subscribe } from "../../../firebaseConfig/firebase";




const SubscriptionSection = () => {
//----------STRIPE DATA RETRIEVAL TEST----------------------\\

///retrieve stripe data
const [stripeData, setStripeData] = useState([0])
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch('https://api.npms.io/v2/search?q=react')
        .then(response => response.json())
        .then(data => setStripeData(data));
  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  

const { user } = useContext(GlobalContext);
const [loading, setLoading] = useState(false);

const handleSubscriptionButtonClick = async (apiId) => {
  if (!user) {
    // User is not logged in, redirect to sign-in page
    window.location.href = window.location.href + "signin";
    return;
  }

  setLoading(true);

  try {
    subscribe(user, apiId);
  } catch (error) {
    console.error(error);
  }

  setLoading(false);
};

//----------STRIPE DATA RETRIEVAL TEST----------------------\\


  let animation_value_array = [-20,190,-20,1,1,0]
  const changeToNegative = (arr) => {
    return arr.reduce((acc, val) => {
       const negative = val < 0 ? val : val * -1;
       return acc.concat(negative);
    }, []);
 };

 const {toggleValue, setToggleValue} = useContext(GlobalContext);
 const {keyValue, setKeyValue} = useContext(GlobalContext);
 const {annualToggleClicked, setAnnualToggleClicked} = useContext(GlobalContext);
 const products = toggleValue === "monthly" ? productsMonthly : productsYearly;
  return (
    <div className='subscription_section_parent' id = 'subscription_section_parent'>
        <div className='subscription_section_contents' id='subscription_section_contents'>
            <TextBox number_value='Ready to de-stress?' default_text='Choose a plan for you.'/>
            <div className='subscription_section_toggle_items'>

            <PlanToggle/>
            <AnimatePresence mode='wait'>
    { toggleValue == "monthly"?  
    <motion.h1 
    key = {"monthly_toggle_text"}
    initial={{            
      scaleX: 1,
      opacity: 0,
      transition: { type: 'linear', stiffness: 50 },
      translateY: -10,
      originY: 1,
      originX: 0}}
    animate={{            
      scaleX: 1,
      translateY: 0,
      opacity: 1,
      transition: { type: 'linear', stiffness: 50 },
      translateY: 0,
      originY: 1,
      originX: 1}}
    exit={{            
      scaleX: 0.97,
      translateY: -50,
      opacity: 0,
      transition: { type: 'linear', stiffness: 50 },
      translateY: -20,
      originY: 1,
      originX: 0}}
    transition={{ delay: .7, duration: 1 }}
    className='subscription_toggle_text'>Viewing <span>{"monthly"}</span> plan</motion.h1>
    
    
    : 

    <motion.h1 
    key = {"yearly_toggle_text"}
    initial={{            
      scaleX: 1,
      opacity: 0,
      transition: { type: 'linear', stiffness: 50 },
      translateY: -10,
      originY: 1,
      originX: 0}}
    animate={{            
      scaleX: 1,
      translateY: 0,
      opacity: 1,
      transition: { type: 'linear', stiffness: 50 },
      translateY: 0,
      originY: 1,
      originX: 1}}
    exit={{            
      scaleX: 0.97,
      translateY: -50,
      opacity: 0,
      transition: { type: 'linear', stiffness: 50 },
      translateY: -20,
      originY: 1,
      originX: 0}}
    transition={{ delay: .7, duration: 1}}
    className='subscription_toggle_text_orange'>Viewing <span>{"yearly"}</span> plan</motion.h1>
    }
    </AnimatePresence>


            </div>
            <div className='subscription_card_row'>
            <div className='subscription_card_row_mobile_contents'>
              
            <SubscriptionCardVariantMobile
                name ={products[1].name}
                price = {products[1].price}
                details = ""
                description = {products[1].description}
                first_detail={products[1].subscriptionDetails[0]}
                second_detail={products[1].subscriptionDetails[1]}
                third_detail={products[1].subscriptionDetails[2]}
                button_link = {products[1].directLink}
                button_function = {()=>{handleSubscriptionButtonClick(products[1].apiId)}}
                animation_array = {[-20,190,20,-2,-1,1]}
                enter_animation_values = {data.middle_enter_values}
                animate_animation_values = {data.animate_values}
                exit_animation_values = {data.middle_exit_values}
                product_index = {1}      
                key_value = {"variant_mobile"}
              />
              <SubscriptionCardMobile
                name ={products[0].name}
                price = {products[0].price}
                details = ""
                description = {products[0].description}
                first_detail={products[0].subscriptionDetails[0]}
                second_detail={products[0].subscriptionDetails[1]}
                third_detail={products[0].subscriptionDetails[2]}
                button_text = "Subscribe"
                button_function = {()=>{handleSubscriptionButtonClick(products[0].apiId)}}
                button_link = {products[0].directLink}
                animation_array = {animation_value_array}
                key_value = "keyValueMobile"
                enter_animation_values = {data.enter_values}
                animate_animation_values = {data.animate_values}
                exit_animation_values = {data.exit_values}
                product_index = {0}       
                display = {false}
              />
             <SubscriptionCardMobile
                name ={products[2].name}
                price = {products[2].price}
                details = ""
                description = {products[2].description}
                first_detail={products[2].subscriptionDetails[0]}
                second_detail={products[2].subscriptionDetails[1]}
                third_detail={products[2].subscriptionDetails[2]}
                button_text = "Subscribe"
                button_link={products[2].directLink}
                button_function = {()=>{handleSubscriptionButtonClick(products[2].apiId)}}
                animation_array = {[-20,190,20,-2,-1,1]}
                enter_animation_values = {data.reverse_enter_values}
                animate_animation_values = {data.reverse_animate_values}
                exit_animation_values = {data.reverse_exit_values}
                product_index = {2} 
                key_value = "keyValueMobile1"
                display = {false}
              />     
            </div>
 
          
           
              <div className='subscription_card_row_contents'>
              
          
        
                  <SubscriptionCard 
                  name ={products[0].name}
                  price = {products[0].price}
                  details = ""
                  description = {products[0].description}
                  first_detail={products[0].subscriptionDetails[0]}
                  second_detail={products[0].subscriptionDetails[1]}
                  third_detail={products[0].subscriptionDetails[2]}
                  button_text = "Subscribe"
                  button_function = {()=>{handleSubscriptionButtonClick(products[0].apiId)}}
                  button_link = {products[0].directLink}
                  animation_array = {animation_value_array}
                  key_value = "keyValue"
                  enter_animation_values = {data.enter_values}
                  animate_animation_values = {data.animate_values}
                  exit_animation_values = {data.exit_values}
                  product_index = {0}
                  display = {false}
                  />
        
                  
  

                <SubscriptionCardVariant
                name ={products[1].name}
                price = {products[1].price}
                details = ""
                description = {products[1].description}
                first_detail={products[1].subscriptionDetails[0]}
                second_detail={products[1].subscriptionDetails[1]}
                third_detail={products[1].subscriptionDetails[2]}
                button_link = {products[1].directLink}
                button_function = {()=>{handleSubscriptionButtonClick(products[1].apiId)}}
                animation_array = {[-20,190,20,-2,-1,1]}
                enter_animation_values = {data.middle_enter_values}
                animate_animation_values = {data.animate_values}
                exit_animation_values = {data.middle_exit_values}
                product_index = {1}
                />
                <SubscriptionCard 
                name ={products[2].name}
                price = {products[2].price}
                details = ""
                description = {products[2].description}
                first_detail={products[2].subscriptionDetails[0]}
                second_detail={products[2].subscriptionDetails[1]}
                third_detail={products[2].subscriptionDetails[2]}
                button_text = "Subscribe"
                button_link={products[2].directLink}
                button_function = {()=>{handleSubscriptionButtonClick(products[2].apiId)}}
                animation_array = {[-20,190,20,-2,-1,1]}
                enter_animation_values = {data.reverse_enter_values}
                animate_animation_values = {data.reverse_animate_values}
                exit_animation_values = {data.reverse_exit_values}
                product_index = {2}
                display = {null}
                />
          
              </div>
          {/* {console.log(productsYearly[0])} */}
            </div>
        </div>
    </div>
  )
}

export default SubscriptionSection