import React from 'react'
import './DemoVideoOverlay.css'
import { useState, useContext } from 'react'
import MainCloseButton from '../../UI/atoms/MainCloseButton/MainCloseButton'
import { GlobalContext } from '../../../contexts/GlobalContext'
import MainVideoDisplay from '../../UI/atoms/MainVideoDisplay/MainVideoDisplay'
import { motion, AnimatePresence } from 'framer-motion'


const DemoVideoOverlay = ({display_state}) => {
  const [open, setOpen] = useState(false)
  const { user, videoOpen, setVideoOpen } = useContext(GlobalContext);
  return (
    <AnimatePresence mode='wait'>
    <motion.div 
    className={ display_state == false? 'demo_video_overlay_parent' : 'demo_video_overlay_parent_displayed'}
    key = {"video_overlay_parent"}
    initial={{ translateY: 15, opacity: 0 }}
    animate={{ translateY:0, opacity: 1 }}
    exit={{
      translateY:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: .2 , duration:.3}}
    
    
    
    
    
    
    
    
>
      <MainCloseButton button_function={()=>{setVideoOpen(false)}}/>

    <div>
        {/* <iframe src="https://www.loom.com/embed/6e9ff19a2c484ca58bc1bf65a6117137" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ width: "1080px", height: "720px"}}>
        </iframe> */}
        <MainVideoDisplay/>
    </div>

    </motion.div>
  </AnimatePresence>
  )
}

export default DemoVideoOverlay