import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import IsLoggedin from "../utils/IsLoggedin";

//Requires users to be authenticated before acccessing any page that is wrapped in this component
const ProtectedRoute = ({ redirectPath = "/signin", children }) => {
    const { user, setUser, isSubscribed, setIsSubscribed, setScrolltoSubscriptions, needsSubscription, setNeedsSubscription } = useContext(GlobalContext);
    const [isAuthChecked, setIsAuthChecked] = useState(false);
    const [isSubscribedChecked, setIsSubscribedChecked] = useState(false);
    
  
    useEffect(() => {
      IsLoggedin(setUser, setIsSubscribed, isSubscribed)
        .then(() => setIsAuthChecked(true))
        .catch((error) => console.error(error));
    }, [setUser, setIsSubscribed, isSubscribed]);
  
    useEffect(() => {
      if (isAuthChecked && isSubscribed != null) {
        setIsSubscribedChecked(true);
      }
    }, [isAuthChecked, isSubscribed]);
  
    if (!isAuthChecked) {
      return <div>Loading...</div>;
    }
  
    if (!user) {
      return <Navigate to={redirectPath} replace />;
    }
  
    if (needsSubscription && !isSubscribedChecked) {
      return <div>Loading...</div>;
    }
  
    if (user && needsSubscription && !isSubscribed) {
    //   console.log(isSubscribed);
      setScrolltoSubscriptions(true);
      return <Navigate to={"/"} />;
    }
  
    return children;
  };
  
  

export default ProtectedRoute;
