const HeroLoggedIn = [
  {
    header: "One Question. One Result. Stress-Free decisions for everyday life.",
    paragraph: "Welcome back! Click below to make a new decision",
    button_text: "Make a decision",
    arrow_text: "Make a decision",

  },

];

export default HeroLoggedIn;
