import React from 'react'
import './AccentButton.css'
import { Router, Link } from 'react-router-dom'

import { RiArrowRightFill, RiPlayFill } from 'react-icons/ri'
const AccentButton = ({
  button_text = "Start Free Trial", 
  button_icon = <RiPlayFill className='button_icon'/>, 
  button_link = null, 
  button_function,
}) => {

  return (
    <Link to = {button_link} className = "button_parent_container">
    <button className= {button_icon != "" ? "accent_button" : "accent_button_no_icon"} onClick = {button_function}>

{button_icon}
{button_text}
</button>
    </Link>

  )
}
// https://buy.stripe.com/5kA7tk53z2sLbuwbIJ
export default AccentButton