import React from 'react'
import './BannerSection.css'
import EmailBox from '../../UI/atoms/EmailBox/EmailBox'
import image from '../../../image_assets/mother-and-baby-2818270_1920-PhotoRoom.png-PhotoRoom.png'
const BannerSection = () => {
  return (
    <div className='banner_parent'>
        <div className = 'banner_contents'>
            <div className='left_items'>
                <h1 className='email_header'>
                    Subscribe to Our Newsletter
                </h1>
            <EmailBox/>
            </div>
            <div className='right_items'>
            <img src={image} alt="Woman Holding Baby" className='right_image'/>
            </div>
        </div>
    </div>
  )
}

export default BannerSection