import React, { useContext, useEffect, useRef, useState } from 'react'
import './DecisionHero.css'
import DecisionSearchBox from '../../UI/atoms/DecisionSearchBox/DecisionSearchBox'
import DecisionSuggestionBox from '../../UI/atoms/DecisionSuggestionBox/DecisionSuggestionBox'
import ExpandableDecisionSearchBox from '../../UI/atoms/ExpandableDecisionSearchBox/ExpandableDecisionSearchBox'
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
import AnswerModelButton from '../../UI/atoms/AnswerModelButton/AnswerModelButton';
import Tooltip from '../../UI/atoms/Tooltip/Tooltip';
import {RiInformationLine} from 'react-icons/ri/'
import {TbSwitch} from 'react-icons/tb/'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { motion, AnimatePresence } from 'framer-motion';
import suggestions from '../../../Suggestions';

const DecisionHero = ({header_text = "Make a decision below.", model_button_function = null}) => {

  const [suggestionOne, setSuggestionOne] = useState()
  const [suggestionTwo, setSuggestionTwo] = useState("Best animated movie on Hulu")
  const [suggestionThree, setSuggestionThree] = useState("Greatb")
  const [suggestionFour, setSuggestionFour] = useState()

  const { 
    decisionModelMode, setDecisionModelMode,
  } = useContext(GlobalContext);

  //Changes model based on user click of UI button
  function modelClick(){
    if (decisionModelMode == "one-answer-model"){
      setDecisionModelMode("few-answers-model")
    } else{
      setDecisionModelMode("one-answer-model")
    }
  }

  //Randomizes suggestions for decision page
  function getRandomString(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }

  //Sets randomized suggestions on page load
  useEffect(() => {
    setSuggestionOne(getRandomString(suggestions[0]))
    setSuggestionTwo(getRandomString(suggestions[1]))
    setSuggestionThree(getRandomString(suggestions[2]))
    setSuggestionFour(getRandomString(suggestions[3]))
  }, [])

  // setSuggestions()


  

  return (
    <div className='decision_hero_parent'>
      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='decision_hero_contents'>
          <div className='top_items'>
          <h1 className='decision_header'>
        {header_text.split(" ").slice(0, header_text.split(" ").length-1).join(" ")}
          <span> {header_text.split(" ").slice(-1)[0]}</span>
        </h1>
          <div className='model_details_container'>

            <div className='model_details_container_top_items'>
              {decisionModelMode == "one-answer-model" ?
              <motion.p
              key = {"default_mode_header"}
              initial={{            
                scaleX: 1,
                opacity: 0,
                transition: { type: 'linear', stiffness: 50 },
                translateY: -10,
                originY: 1,
                originX: 0}}
              animate={{            
                scaleX: 1,
                translateY: 0,
                opacity: 1,
                transition: { type: 'linear', stiffness: 50 },
                translateY: 0,
                originY: 1,
                originX: 1}}
              exit={{            
                scaleX: 0.97,
                translateY: -50,
                opacity: 0,
                transition: { type: 'linear', stiffness: 50 },
                translateY: -20,
                originY: 1,
                originX: 0}}
              transition={{ delay: .7, duration: 1}}
              className='mode_header'>Currently in <span>1-Answer</span> mode
              <Tooltip/>
              </motion.p>
              : 

              <motion.p
              key = {"other_mode_header"}
              initial={{            
                scaleX: 1,
                opacity: 0,
                transition: { type: 'linear', stiffness: 50 },
                translateY: -10,
                originY: 1,
                originX: 0}}
              animate={{            
                scaleX: 1,
                translateY: 0,
                opacity: 1,
                transition: { type: 'linear', stiffness: 50 },
                translateY: 0,
                originY: 1,
                originX: 1}}
              exit={{            
                scaleX: 0.97,
                translateY: -50,
                opacity: 0,
                transition: { type: 'linear', stiffness: 50 },
                translateY: -20,
                originY: 1,
                originX: 0}}
              transition={{ delay: .7, duration: 1}}
              className='mode_header'>Currently in <span className='secondary_span'>Few Answers</span> mode
              <Tooltip/>
              </motion.p>


       
              }
              
            </div>

            <div className='model_details_container_lower_items'>
              <p className='mode_subtext'>Want a different mode?</p>
              <AnswerModelButton button_function={()=>{modelClick()}} state_drill = {decisionModelMode}/>
            </div>

          </div>
          </div>

        <DecisionSearchBox/>
    
        <div className='lower_items'>
        <h1 className='suggestions_header'>
            Some <span>suggestions....</span>
            </h1>
        <div className='decision_sugggestions_parent'>

        <DecisionSuggestionBox suggestion_text={suggestionOne}/>
        <DecisionSuggestionBox suggestion_text={suggestionTwo}/>
        <DecisionSuggestionBox suggestion_text={suggestionThree}/>
        <DecisionSuggestionBox suggestion_text={suggestionFour}/>
        </div>
        
        </div>     
        </div>   
    </div>
  )
}

export default DecisionHero