import React, { useContext, useEffect, useRef, useState } from 'react'
import './SignedInScreenHero.css'
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
import {RiInformationLine} from 'react-icons/ri/'
import {RiArrowRightCircleLine} from 'react-icons/ri/'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { motion, AnimatePresence } from 'framer-motion';
import MainButton from '../../UI/atoms/MainButton/MainButton';
import {ReactComponent as StripeContent} from '../../../svg_assets/stripe_blurple.svg';
import SubscriptionCardVariant from '../../UI/molecules/SubscriptionCardVariant/SubscriptionCardVariant';
import data from '../../../data'
import productsMonthly from '../../../ProductsMonthly.js';
import productsYearly from '../../../ProductsYearly.js';
import { subscribe } from "../../../firebaseConfig/firebase";
import {RiArrowLeftUpLine, RiArrowRightUpLine } from 'react-icons/ri/'
import { RiArrowGoBackLine } from 'react-icons/ri/';

const SignedInScreenHero = ({header_text = "Welcome back. Get Started Below.", plan_button_function = null}) => {

  const { 
    decisionModelMode, setDecisionModelMode,
  } = useContext(GlobalContext);

  function modelClick(){
    if (decisionModelMode == "one-answer-model"){
      setDecisionModelMode("few-answers-model")
    } else{
      setDecisionModelMode("one-answer-model")
    }
  }
  


  const products = productsMonthly 

  const { user } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  
  const handleSubscriptionButtonClick = async (apiId) => {
    if (!user) {
      // User is not logged in, redirect to sign-in page
      window.location.href =  "/signin";
      return;
    }
  
    setLoading(true);
  
    try {
      subscribe(user, apiId);
    } catch (error) {
      console.error(error);
    }
  
    setLoading(false);
  };
  


  return (


    <AnimatePresence mode='wait'>
      <motion.div className={'signed_in_hero_parent'}
      key = {2}
      initial={{ translateX: 15, opacity: 0 }}
      animate={{ translateX:0, opacity: 1 }}
      exit={{
        translateX:-15,
        opacity: 0,
        transition: { type: 'ease' },
      }}
      transition={{ delay: 0.6 , duration:.5}}
      >





      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='signed_in_hero_contents'>
          <div className='left_items'>
          <h1 className='signed_in_header'>
        {header_text.split(" ").slice(0, header_text.split(" ").length-1).join(" ")}
          <span> {header_text.split(" ").slice(-1)[0]}</span>
        </h1>
            <div className='sign_in_button_container'>
          <MainButton button_text='Back to Home' button_icon={<RiArrowLeftUpLine className = "button_icon"/>} button_link = {"/"} />
          <MainButton button_text='Make a Decision' button_icon={<RiArrowRightUpLine className = "button_icon"/>} button_link={"/decide"} button_function={()=>{window.localStorage.setItem('1-DECISION-APP', 'false')}} />
          </div>
          
          </div>

  
  
        </div>   
        </motion.div>
        </AnimatePresence>


  )
}

export default SignedInScreenHero