import React from 'react'
import './PlanToggle.css'
import { useState } from 'react'
import { useContext } from 'react'
import { GlobalContext } from '../../../../contexts/GlobalContext'
const PlanToggle = ({}) => {
  const [clicked, setClicked] = useState(true)
  const [annualClicked, setAnnualClicked] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [hoveredClass, setHoveredClass] = useState('annual_toggle')
  const {toggleValue, setToggleValue} = useContext(GlobalContext);
  const {keyValue, setKeyValue} = useContext(GlobalContext);
 //if annual button has been clicked, don't apply hover effect. Otherwise, if annual button has not been clicked, set hovered as true. If clicked is true and hovered is true
//if monthly is clicked and annual button is small (clicked is true), when annual button is hovered, make the monthly transparent - when annual button is exited, switch the monthly class back to normal.
  const monthly_button_class = [clicked == true? 'monthly_toggle':'monthly_toggle_active' ]
  function annual_toggler(){
    setClicked(false) 
    setHovered(false)
    console.log(clicked)
    setToggleValue("annual")
    console.log(toggleValue)
    setKeyValue(1)
  }

  function monthly_toggler(){
    setToggleValue("monthly");
    setClicked(true) ;
    setHovered(false);
    setToggleValue("monthly");
    console.log(clicked);
    console.log(toggleValue, "success");
    setKeyValue(0)

  }
  function annual_hover(){
    (clicked == true) ? setHovered(true) : setHovered(false) ;
    

  }




  return (
    <div className='plan_toggle_parent'>
        <div className='plan_toggle_contents'>
            <button className={hovered == false ?  monthly_button_class[0] : "monthly_toggle_annual_hovered"} onClick = {monthly_toggler}>
                Monthly
                </button>
                <button className= {clicked == true? 'annual_toggle':'annual_toggle_active'} onClick = {annual_toggler} onMouseEnter = {annual_hover} onMouseLeave = {()=>{setHovered(false)}}>
                Annual
                </button>
        </div>
      
    </div>
  )
}

export default PlanToggle