import React from 'react'
import './HomeSubheading.css'
import TextBox from '../../UI/atoms/TextBox/TextBox'
import TextBoxWithScribble from '../../UI/molecules/TextBoxWithScribble/TextBoxWithScribble'
import HowItWorksCard from '../../UI/molecules/HowItWorksCard/HowItWorksCard'
import {ReactComponent as SpamIcon} from '../../../svg_assets/spam_icon.svg';
import {ReactComponent as NumberOne} from '../../../svg_assets/Number_One.svg';
import HowItWorksCardMobile from '../../UI/molecules/HowItWorksCardMobile/HowItWorksCardMobile'
import {ReactComponent as SubHeadingArrow} from '../../../svg_assets/Arrow_Mask_Element_01.svg';
import {ReactComponent as SubHeadingMobileArrow} from '../../../svg_assets/Mobile_steps_arrow.svg';
const HomeSubheading = () => {
  return (
    <div className='home_subheading_parent'>
      <div className='home_subheading_contents'>
        <div className='top_items'>
        <TextBoxWithScribble/>
        <p className='home_subheading_paragraph'>
        Being a parent can be overwhelming, and sometimes you need a little extra help. That's where we come in!
        </p>
        </div>
        <div className='hiwc_card_array'>
          <SubHeadingArrow className = "subheading_arrow"/>
          <SubHeadingMobileArrow className = "mobile_subheading_arrow"/>
        <HowItWorksCardMobile />
        <HowItWorksCardMobile style = "alternate" icon = {<SpamIcon className = "card_icon"/>} default_text='We do not include any ads, irrelevant info, or links that benefit us financially.'/>
        <HowItWorksCardMobile icon = {<NumberOne className = "card_icon"/>} default_text='We return 1 solid decision.'/>

        <HowItWorksCard />
        <HowItWorksCard icon = {<SpamIcon/>} default_text='We do not include any ads, irrelevant info, or links that benefit us financially.'/>
        <HowItWorksCard icon = {<NumberOne/>} default_text='We return 1 solid decision.'/>

        </div>
        </div>
    </div>
  )
}

export default HomeSubheading