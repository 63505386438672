import React, { useState } from 'react'
import './MobileMenuOverlay.css'
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { motion, AnimatePresence } from 'framer-motion';
import data from '../../../../data';
import { Link, useNavigate } from 'react-router-dom';
import HandleSignOut from '../../../../utils/handleSignOut';
import handleSignOut from '../../../../utils/handleSignOut';
const MobileMenuOverlay = ({state}) => {
  const {isClicked, setIsClicked, user, contactOpen, setContactOpen} = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false)
  function contactStateChange(){
    if(contactOpen == true){
        setContactOpen(false)
    } else {

        setContactOpen(true)
    }
  }
  const handleSignOutClick = () => {
    setIsClicked(false);
    handleSignOut(navigate);
    window.location.reload();
  }
  
// projects.defaultOrdering([{field: 'id', direction: 'asc'}])

React.useEffect(() => {
  if (window.innerWidth < 1200) {
    setIsMobile(true)
} else {
    setIsMobile(false)
}
}, []);

//choose the screen size 
const handleResize = () => {
if (window.innerWidth < 1200) {
    setIsMobile(true)
} else {
    setIsMobile(false)
}
}

// create an event listener
useEffect(() => {
window.addEventListener("resize", handleResize)
})

// finally you can render components conditionally if isMobile is True or False 







  return (


<AnimatePresence mode='wait'>
{ isClicked == true && isMobile == true? (
  <motion.div className={isClicked == true ?'mobile_menu_overlay_parent':'mobile_menu_overlay_parent_hidden'}
key = {"mobile_menu_parent"}
initial={{ translateY: 15, opacity: 0 }}
animate={{ translateY:0, opacity: 1 }}
exit={{
  translateY:-15,
  opacity: 0,
  transition: { type: 'ease' },
}}
transition={{ delay: .2 , duration:.3}}

>
    <ul className='menu_list'>
      <li onClick={() =>{setIsClicked(false)}}>
        <Link to = "/">
          Home
        </Link>
      </li>
      {user ? (
         <li onClick={() =>{setIsClicked(false)}}>
            <Link to = "https://billing.stripe.com/p/login/4gw3eh0tQ0Kk5R68ww">
            My Account
          </Link>
          </li>
          ) :
          (
            null
          )
        }  




      <li onClick={() =>{setIsClicked(false)}}>
        <Link to = "https://about.1-decision.com/">
          About Us
        </Link>
      </li>
      <li onClick={() =>{setIsClicked(false)}}>
        <Link to = "/FAQ">
          FAQ
        </Link>
      </li>
      <li onClick={() =>{setIsClicked(false)}}>
        <Link to = "/pricing">
          Pricing
        </Link>
      </li>

      <li onClick={() =>{setIsClicked(false)}}>
        <Link to = "/" onClick={()=>{contactStateChange()}}>
          Contact
        </Link>
      </li>
      {user ? (
      <li onClick={handleSignOutClick}>
        <Link to = "">
          Sign Out
        </Link>
      </li>
      ) :
      (
        <li onClick={() =>{setIsClicked(false)}}>
        <Link to = "/signin">
          Sign In/Sign Up
        </Link>
      </li>
      )
    }
  
    </ul>


</motion.div>


)

: 
null

}
</AnimatePresence>


  )
}

export default MobileMenuOverlay