import React, { useContext } from 'react'
import './ThankYouPage.css'
import NavBar from '../../UI/organisms/NavBar/NavBar'
import DecisionHero from '../../templates/DecisionHero/DecisionHero'
import { motion } from 'framer-motion'

import { GlobalContext } from '../../../contexts/GlobalContext'
import ThankYouHero from '../../templates/ThankYouHero/ThankYouHero'
const ThankYouPage = () => {

  const {setNeedsSubscription} = useContext(GlobalContext);
  setNeedsSubscription(true)

  return (
    <motion.div className='thank_you_page_parent'
    initial={{ translateX: 15, opacity: 0 }}
    animate={{ translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: 0.6 , duration:.5}}
    >
    <NavBar/>
    <ThankYouHero header_text={"Thanks!"} 
    paragraph_text = {"You are all set! Now click the button below and make your 1st decision."}
    button_text = {"Make a Decision"}
    />
    </motion.div>
  )
}

export default ThankYouPage