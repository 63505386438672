import React, { useContext, useEffect, useLayoutEffect } from 'react'
import './SignInPage.css'
// import { FirebaseuiAuth } from 'react-firebaseui';
import {uiConfig, firebase} from '../../../firebaseConfig/firebase';
import IsLoggedin from '../../../utils/IsLoggedin';
import { Navigate } from 'react-router-dom';
import NavBar from '../../UI/organisms/NavBar/NavBar'
import { GlobalContext } from '../../../contexts/GlobalContext';
import StyledFirebaseAuth from './StyledFirebaseAuth.tsx';
import { motion } from 'framer-motion'
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
import { Helmet } from 'react-helmet';
import { subscribe } from "../../../firebaseConfig/firebase";
import { useState } from 'react';
import productsMonthly from '../../../ProductsMonthly.js';
import MainButton from '../../UI/atoms/MainButton/MainButton'
import SinglePlanFlowHero from '../../templates/SinglePlanFlowHero/SinglePlanFlowHero'
import SignedInScreenHero from '../../templates/SignedInScreenHero/SignedInScreenHero';
import ConditionalSignInComponent from '../../templates/ConditionalSignInComponent/ConditionalSignInComponent';


const SignInPage = () => {
  const {user, setUser, isSubscribed,  setIsSubscribed, setNeedsSubscription} = useContext(GlobalContext)
  const [trigger, setTrigger] = useState(false)
  setNeedsSubscription(false)
  const products = productsMonthly 
  useEffect(() => {
    IsLoggedin(setUser, setIsSubscribed);
  }, [setUser, setIsSubscribed]);
  const [loading, setLoading] = useState(false);
  const handleSubscriptionButtonClick = async (apiId) => {
    if (!user) {
      // User is not logged in, redirect to sign-in page
      window.location.href = window.location.href;
      return;
    }
  
    setLoading(true);
  
    try {
      subscribe(user, apiId);
    } catch (error) {
      console.error(error);
    }
  
    setLoading(false);
  };

const [displayMode, setDisplayMode] = useState(0)

const [activateMode, setActivateMode] = useState(0)
useEffect(() => {
setActivateMode(1)
console.log(activateMode, "activateMode")
}, [displayMode]);

useLayoutEffect(() => {
  // setDisplayMode(1)
  console.log(displayMode, "displaymode test")
  user && isSubscribed == true && isSubscribed != null && isSubscribed != undefined?
  setDisplayMode(2)
  :
  user &&  !isSubscribed  && window.localStorage.getItem('1-DECISION-APP') =='true' ?
  setDisplayMode(3)
  :
  user &&  isSubscribed == null || isSubscribed == undefined && window.localStorage.getItem('1-DECISION-APP') == 'true' ?
  setDisplayMode(5)
  :
  !user &&  !isSubscribed ?
  setDisplayMode(8)
  :
  !user ? 
  setDisplayMode(6)
  :
  
  setDisplayMode(7)
  



  }, [user, isSubscribed, window.localStorage.getItem('1-DECISION-APP')]);
  




  return (
    <motion.div className='sign_in_page_parent'
    initial={{ translateX: 15, opacity: 0 }}
    animate={{ translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: 0.6 , duration:.5}}   
    >




{/* {

  
user && isSubscribed == true && isSubscribed != null && isSubscribed != undefined? 
setDisplayMode(1)
:
user &&  !isSubscribed  && window.localStorage.getItem('1-DECISION-APP') =='true'  ?
setDisplayMode(2)
:
null
} */}


      <Helmet>
        <title>Sign-in/Sign-up | 1-Decision</title>
      </Helmet>
      <NavBar/>
      <main className='sign_in_page_contents'>
      <img className = "blur_circle_1" src = {blur_circle_1} alt=""/>
      <img className = "blur_circle_2" src = {blur_circle_2} alt=""/>
      <div className='sign_in_page_container'>
        <div className='sign_in_top_items'>
        {user && isSubscribed == true && isSubscribed != null && isSubscribed != undefined? 
          <h1 className='faq_header'>

          </h1>
          : 
          <div className='signin_items'>

          <h1 className='faq_header'>
            Sign In Below
            
          </h1>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </div>

          
        }



        </div>
        {displayMode == 7?
        
        <ConditionalSignInComponent displayMode={3} sign_in_element={<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />}/> :
        <div>
        <ConditionalSignInComponent displayMode={displayMode} sign_in_element={<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />}/>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
        }



      </div>

      </main>
      </motion.div>
  )
}

export default SignInPage