import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

let _cio = window._cio || [];

const STRIPE_PUBLISHABLE_KEY =
  "pk_live_51KkvnCDa6qlpCRXePOaFkDEPbG5aTgc0s8XHXwy87nFluU6wJboQsxpBINW5IUU7x1KXLTPYRyOzoHM62cSzOERA00mfnqeufl";

//Defines Firebase project credentials
const firebaseConfig = {
  apiKey: "AIzaSyDC9JvnlYjrBhb3OuRhy1q5yG7sshHRQOA",
  authDomain: "bubblemvp-1646849141634.firebaseapp.com",
  databaseURL: "https://bubblemvp-1646849141634-default-rtdb.firebaseio.com",
  projectId: "bubblemvp-1646849141634",
  storageBucket: "bubblemvp-1646849141634.appspot.com",
  messagingSenderId: "377787314096",
  appId: "1:377787314096:web:e2a36c7e13d65f58787075",
  measurementId: "G-Z79SQDFRKJ",
};

const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY);

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth(); // <-- import the 'auth' object
const db = firebase.firestore();

//Defines the Firebase Auth UI configuration for the firebase ui sign in page
const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

//Check to see if user has subscription
function startDataListeners(currentUser, setIsSubscribed) {
  if (!currentUser) {
    setIsSubscribed(false);
    return;
  }

  db.collection("customers")
    .doc(currentUser.uid)
    .collection("subscriptions")
    .where("status", "in", ["trialing", "active"])
    .onSnapshot(async (snapshot) => {
      if (snapshot.empty) {
        setIsSubscribed(false);
        return;
      }
      setIsSubscribed(true);
    });
}

// Checkout handler to direct users to Stripe Checkout
async function subscribe(currentUser, apiId) {
  /* Generates unique client reference ID for the checkout session */
  function getClientReferenceId() {
    return (
      (window.Rewardful && window.Rewardful.referral) ||
      "checkout_" + new Date().getTime()
    );

    /* Reference to a new document in the checkout_sessions collection for current user in firestore. 
  The new document contains these fields that are set to be used in the Stripe Checkout Session that will be created later on. */
  }
  const docRef = await db
    .collection("customers")
    .doc(`${currentUser.uid}`)
    .collection("checkout_sessions")
    .add({
      price: apiId,
      allow_promotion_codes: true,
      success_url: `${window.location.origin}/thanks`,
      cancel_url: window.location.origin,
      clientReferenceId: getClientReferenceId(),
    });

  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot((snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      const email = currentUser.email;

      window.Rewardful &&
        window.Rewardful.convert &&
        window.Rewardful.convert({ email });

      // We have a session, let's redirect to Checkout
      const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY);

      stripe.redirectToCheckout({
        sessionId,
      });
    }
  });
}

//For customer.io tracking user activity logs
firebase.auth().onAuthStateChanged((firebaseUser) => {
  if (firebaseUser) {
    const customerio = _cio.identify;
    const userId = firebaseUser.uid;
    // const stripeCustomerId = firebaseUser.stripeId;
    // console.log(stripe.customers);

    db.collection("customers")
      .doc(firebaseUser.uid)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"])
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          customerio({
            id: firebaseUser.uid, // Use Firebase UID as the unique identifier for the user
            email: firebaseUser.email,
            first_name: firebaseUser.displayName.split(" ")[0], // Add any attributes you'd like to use in the email subject or body.
            last_name: firebaseUser.displayName.split(" ")[1],
            created_at: firebaseUser.metadata.creationTime,
            subscription_status: "none",
            subscription_plan: "none",
            subscription_interval: "none",
            // Add any other data for customers without a subscription
          });
        } else {
          // User has an active subscription
          const subscription = snapshot.docs[0].data();
          console.log(subscription);
          // Update identifiers in Customer.io accordingly
          customerio({
            id: firebaseUser.uid, // Use Firebase UID as the unique identifier for the user
            email: firebaseUser.email,
            first_name: firebaseUser.displayName.split(" ")[0], // Add any attributes you'd like to use in the email subject or body.
            last_name: firebaseUser.displayName.split(" ")[1],
            created_at: firebaseUser.metadata.creationTime,
            subscription_status: subscription.status,
            subscription_plan: subscription.items[0].price.product.name,
            subscription_interval: subscription.items[0].plan.interval,
            // Add any other subscription-related data you want to send to Customer.io
          });
        }
      });

    // const stripeCustomer = stripe.customers.retrieve(stripeCustomerId);

    // window.addEventListener("load", () => {
    // Initialize Stripe with your publishable key

    // Assuming you have access to the Stripe customer ID stored in Firebase

    // Retrieve Stripe customer object and check subscription status

    // if (stripeCustomer.subscriptions.data.length > 0) {
    //   // Customer has an active subscription
    //   const subscription = stripeCustomer.subscriptions.data[0];

    //   customerio({
    //     id: firebaseUser.uid, // Use Firebase UID as the unique identifier for the user
    //     email: firebaseUser.email,
    //     first_name: firebaseUser.displayName.split(" ")[0], // Add any attributes you'd like to use in the email subject or body.
    //     last_name: firebaseUser.displayName.split(" ")[1],
    //     created_at: firebaseUser.metadata.creationTime,
    //     subscription_status: subscription.status,
    //     subscription_plan: subscription.plan.nickname,
    //     subscription_interval: subscription.plan.interval,
    //     // Add any other subscription-related data you want to send to Customer.io
    //   });
    // } else {
    //   // Customer does not have an active subscription
    //   customerio({
    //     id: firebaseUser.uid, // Use Firebase UID as the unique identifier for the user
    //     email: firebaseUser.email,
    //     first_name: firebaseUser.displayName.split(" ")[0], // Add any attributes you'd like to use in the email subject or body.
    //     last_name: firebaseUser.displayName.split(" ")[1],
    //     created_at: firebaseUser.metadata.creationTime,
    //     subscription_status: "none",
    //     subscription_plan: "none",
    //     subscription_interval: "none",
    //     // Add any other data for customers without a subscription
    //   });
    // }
    // });
  }
});

function storeQueryinFirebase(user, query, result, references, mode) {
  const userId = user.uid;
  const timestamp = firebase.firestore.Timestamp.now();

  const data = {
    query: query,
    result: result,
    references: references,
    hidden: false,
    liked: null,
    modelMode: mode,
    userUid: userId,
    timestamp: timestamp,
  };

  db.collection("decisions")
    // .doc(generateRandomString(10))
    .add(data)
    .then(() => {
      // console.log("Input data stored in Firestore");
    })
    .catch((error) => {
      console.error("Error storing input data in Firestore:", error);
    });
}

function storeAnonQueryinFirebase(query, result, references, mode) {
  // const userId = user.uid;
  const timestamp = firebase.firestore.Timestamp.now();

  const data = {
    query: query,
    result: result,
    references: references,
    hidden: false,
    liked: null,
    modelMode: mode,
    userUid: null,
    timestamp: timestamp,
  };

  db.collection("decisions")
    // .doc(generateRandomString(10))
    .add(data)
    .then(() => {
      // console.log("Input data stored in Firestore");
    })
    .catch((error) => {
      console.error("Error storing input data in Firestore:", error);
    });
}

// Listen for Stripe Checkout completion events to track purchases using Customer.io
// const onCheckoutCompleted = (session) => {
//   // Track the purchase event
//   Customerio.track("Purchase", {
//     price: session.amount_total / 100, // convert amount to dollars
//     // product: "Widget", // replace with actual product name
//   });
// };

//Event listener to handle checkout completion
// window.addEventListener("popstate", () => {
//   const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY);
//   stripe.then((stripe) => {
//     stripe.retrieveCheckoutSession().then((session) => {
//       if (session && session.payment_status === "paid") {
//         // Payment was successful, track the purchase event
//         onCheckoutCompleted(session);
//       }
//     });
//   });
// });

export {
  firebase,
  uiConfig,
  subscribe,
  startDataListeners,
  storeQueryinFirebase,
  storeAnonQueryinFirebase,
};
