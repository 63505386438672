const HeroLoggedOut = [
  {
    header: `1 Question. 1 Result. Stress-Free Answers for Your Daily Life.`,
    paragraph: `Get The Quick, Stress-Free Answer in Under 30 Seconds through Expert-Backed Recommendations. Simplify Choices in Entertainment, Shopping, Dining, Travel, and More. 

    We are “Cruise Control for Your Daily Life.”`,
    button_text: "Start Free Trial",
    arrow_text: "Try Us Now!",

  },

];

export default HeroLoggedOut;
