import React from 'react'
import'./SubscriptionCard.css'
import {ReactComponent as SubscriptionBoxScribble} from '../../../../svg_assets/Thin_Scribble.svg';
import MainButton from '../../atoms/MainButton/MainButton'
import blur_triangles from '../../../../image_assets/Blur_Triangles.webp' 
import { motion, AnimatePresence } from 'framer-motion';
import { Context, useContext } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext'
import productsMonthly from '../../../../ProductsMonthly';
import productsYearly from '../../../../ProductsYearly';
const SubscriptionCard = ({
name = "Lite", 
price = "3.50", 
term = "mo", 
description = "Limited features compared to core plans",
first_detail = "placeholder text",
second_detail = "placeholder text",
third_detail = "placeholder text",
button_text = "Start Free Trial",
button_function,
button_link = "",
animation_array = [0,0,0],
enter_animation_values = {},
animate_animation_values = {},
exit_animation_values = {},
key_value = 2,
product_index = 0,
display = true
}) => {

  const {toggleValue, setToggleValue} = useContext(GlobalContext);
// {console.log(productsYearly[0]?.subscriptionDetails)}
  let exit_data = enter_animation_values.reverse_enter_values
  return (
    <AnimatePresence mode='wait'>
    { toggleValue == "monthly"?  (
      <motion.div className={ display == true? 'subscription_card_parent': "subscription_card_parent_hide"}
      key = {key_value}
      initial={enter_animation_values}
      animate={animate_animation_values}
      exit={exit_animation_values}
      transition={{ delay: .1, duration: .8 }}
      
      >
          <div className='subscription_card_contents'>
              <div className='top_items'>
                <img src = {blur_triangles} className = 'triangle_background'/>
                  <h1 className='card_heading'>
                      {productsMonthly[product_index].name}
                  </h1>
                  <h2 className='card_price'>
                  ${productsMonthly[product_index].price}/<span>{"mo"}</span>
                  </h2>
                  <p className='plan_description'>
                    {productsMonthly[product_index].description}
                  </p>
  
              </div>
              <div className='center_line'>
                  <SubscriptionBoxScribble/>
              </div>
              <div className='bottom_items'>
                <ul className='subscription_details_list'>
                  <li>{productsMonthly[product_index].subscriptionDetails[0]}</li>
                  <li>{productsMonthly[product_index].subscriptionDetails[1]}</li>
                  <li>{productsMonthly[product_index].subscriptionDetails[2]}</li>
                </ul>
                <MainButton button_icon={""} button_text = {button_text} button_function = {button_function} button_link = {null}/>
                {/* <MainButton button_icon={""} button_text = {button_text} button_function = {button_function} button_link = {productsMonthly[product_index].directLink}/> */}
              </div>
              
  
          </div>
      </motion.div>
 
    )
    
    : 
    <motion.div className={ display == true? 'subscription_card_parent': "subscription_card_parent_hide"}
    key = {1}
    initial={enter_animation_values}
    animate={animate_animation_values}
    exit={exit_animation_values}
    transition={{ delay: .1, duration: .8 }}
    
    >
        <div className='subscription_card_contents'>
            <div className='top_items'>
              <img src = {blur_triangles} className = 'triangle_background'/>
                <h1 className='card_heading'>
                    {productsYearly[product_index].name}
                </h1>
                <h2 className='card_price'>
                ${productsYearly[product_index].price}/<span>{"year"}</span>
                </h2>
                <p className='plan_description'>
                  {productsYearly[product_index].description}
                </p>

            </div>
            <div className='center_line'>
                <SubscriptionBoxScribble/>
            </div>
            <div className='bottom_items'>
              <ul className='subscription_details_list'>
                <li>{productsYearly[product_index]?.subscriptionDetails[0]}</li>
                <li>{productsYearly[product_index]?.subscriptionDetails[1]}</li>
                <li>{productsYearly[product_index]?.subscriptionDetails[2]}</li>
              </ul>
              <MainButton 
              button_icon={""}
               button_text = {button_text} 
               button_function = {button_function} 
               button_link = {productsYearly[product_index].directLink}
              />
            </div>
            

        </div>
    </motion.div>
    
   
    }
    </AnimatePresence>


  )
}

export default SubscriptionCard