import React from 'react'
import './LoadingOverlay.css'
import { useContext } from 'react';
import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import blur_circle_1 from '../../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../../image_assets/Blur_Circle_5.webp'; 
import Lottie from 'react-lottie';
import animationData from '../../../../lotties/decision-loader';



import { GlobalContext } from "../../../../contexts/GlobalContext";
const LoadingOverlay = ({header_text = "Loading...", state = null, class_name = "loading_overlay_parent_default"}) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


    const { 
        decSearchQuery, setDecSearchQuery, 
        isDecResultsLoading, setIsDecResultsLoading, 
        isDecDropdownActive, setIsDecDropdownActive, 
        decLoadingMessage, setDecLoadingMessage, 
        decSearchResults, setDecSearchResults, 
        decReferencesList, setDecReferencesList 
      } = useContext(GlobalContext);


    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
  
    // The state for our timer
    const [timer, setTimer] = useState('00:00:00');
  
  
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
  
  
    const startTimer = (e) => {
        let { total, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
  
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(

                (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
  
  
    const clearTimer = (e) => {
  
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:00:40');
  
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
  
    const getDeadTime = () => {
        let deadline = new Date();
  
        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 40);
        return deadline;
    }
  
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
  
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
  
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }
  


  return (
    <AnimatePresence>
    <motion.div 
    className= { state == true ?'loading_overlay_parent' : 'loading_overlay_parent_default'  }
    initial={{ scale: 1.2, translateX: 15, opacity: 0 }}
    animate={{ scale: 1, translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease', duration: .5},
    }}
    transition={{ delay: 0.2 , duration:.5}}      
    >


      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='loading_hero_contents'>
          <div className='loading_top_items'>
          <h1 className='loading_header'>
        {header_text.split(" ").slice(0, header_text.split(" ").length-1).join(" ")}
          <span> {header_text.split(" ").slice(-1)[0]}</span>
        </h1>
      <h1 className='loading_time_parent'>Estimated Time : {timer}s</h1>

          </div>

        <div className='lower_items'>
        <div>
      <Lottie 
	    options={defaultOptions}
        height={150}
        width={400}
        loop
        autoplay
       
      />
    </div>

        <div className='loading_sugggestions_parent'>

        </div>
        
        </div>     
        </div>   
 
    
    
    
    
    
    
    </motion.div>
    </AnimatePresence>
  )
}

export default LoadingOverlay