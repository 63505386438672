import React, { useContext } from 'react'
import './SinglePlanFlowPage.css'
import NavBar from '../../UI/organisms/NavBar/NavBar'
import SinglePlanFlowHero from '../../templates/SinglePlanFlowHero/SinglePlanFlowHero'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../../contexts/GlobalContext'

const SinglePlanFlowPage = () => {
  const {setNeedsSubscription} = useContext(GlobalContext);
  setNeedsSubscription(true)
  
  return (
    <motion.div className='decision_page_parent'
    initial={{ translateX: 15, opacity: 0 }}
    animate={{ translateX:0, opacity: 1 }}
    exit={{
      translateX:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: 0.6 , duration:.5}}
    >
      <Helmet>
        <title>Decision App | 1-Decision</title>
      </Helmet>
      <NavBar/>
      <SinglePlanFlowHero/>
    </motion.div>
  )
}

export default SinglePlanFlowPage