import React from 'react'
import './MainCloseButton.css'
import { useState } from 'react'
const MainCloseButton = ({button_function, state = true}) => {

  return (
    <button className='main_close_button_parent' onClick={button_function}>
    <ul className='main_close_button_bars_list'>
      <li className={state == false ?  'top_bar': 'top_bar_clicked'}/>
      <li className={state == false ? 'middle_bar': 'middle_bar_clicked'}/>
      <li className={state == false ? 'bottom_bar': 'bottom_bar_clicked'}/>
    </ul>
  </button>
  )
}

export default MainCloseButton