import React from 'react'
import './EmailBox.css'
import MainButton from '../MainButton/MainButton'
import { IoMailSharp } from 'react-icons/io5'
const EmailBox = () => {
  return (
    <div className='email_parent'>
        <div className='email_contents_parent'>
            <input className='text_input' type = 'email' defaultValue={"Enter Your Email"}></input>
            <MainButton button_text = "Subscribe" button_icon={<IoMailSharp className = "button_icon"/>}/>
        </div>
    </div>
  )
}

export default EmailBox