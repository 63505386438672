import React from 'react'
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard'
import './SubscriptionCardVariant.css' 
import { motion, AnimatePresence } from 'framer-motion';
import { Context, useContext } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext';
const SubscriptionCardVariant = ({
  name ="Single",
  price = "9.90" ,
  details = "",
  description = "Comes with a 9-day free trial and more",
  first_detail='9-day Free Trial',
  second_detail='Money-back Guarantee',
  third_detail='Unlimited Decisions',
  button_link = "",
  button_function,
  animation_values_array = [1.2,1,0,1],
  enter_animation_values = {},
  animate_animation_values = {},
  exit_animation_values = {},
  product_index = 0
}) => {
  const {toggleValue, setToggleValue} = useContext(GlobalContext);
  return (
    <AnimatePresence mode = "wait" >
    { toggleValue == "monthly"?  (

      <motion.div className='subscription_card_variant_parent'
      key={"monthly_variant"}
      initial={{  translateY: -15, translateX: animation_values_array[1], scale: animation_values_array[0], opacity: 0 , rotateZ: animation_values_array[2].toString() +"deg", originY: 1}}
      animate={{ translateY: 0, translateX: 0, scale: 1, opacity: 1, rotateZ: "0deg", originY: 1 }}
      exit={{
        scaleX: 0.97,
        translateY: 50,
        opacity: 0,
        transition: { type: 'linear', stiffness: 50 },
      }}
      transition={{ delay: .1, duration: .8 }}
      
      
      
      >
          <div className='background_shape_top_left'/>
          <div className='background_shape_bottom_right'/>
          <SubscriptionCard
              name ={name}
              price = {price}
              details = {details}
              description = {description}
              first_detail={first_detail}
              second_detail={second_detail}
              third_detail={third_detail}
              button_link = {button_link}
              button_function = {button_function}
              enter_animation_values = {enter_animation_values}         
              animate_animation_values = {animate_animation_values}
              exit_animation_values = {exit_animation_values}
              product_index = {product_index}
  
          
          />
          
      </motion.div>

 
 
    ): 
    
    

      <motion.div className='subscription_card_variant_parent'
      key={"annual_variant"}
      initial={{  translateY: -5, translateX: animation_values_array[1], scale: animation_values_array[0], opacity: 0 , rotateZ: animation_values_array[2].toString() +"deg", originY: 1}}
      animate={{ translateY: 0, translateX: 0, scale: 1, opacity: 1, rotateZ: "0deg", originY: 1 }}
      exit={{
        scaleX: 0.97,
        translateY: 50,
        opacity: 0,
        transition: { type: 'linear', stiffness: 50 },
      }}
      transition={{ delay: .1, duration: .8 }}
      
      
      
      >
          <div className='background_shape_top_left'/>
          <div className='background_shape_bottom_right'/>
          <SubscriptionCard
              name ={name}
              price = {price}
              details = {details}
              description = {description}
              first_detail={first_detail}
              second_detail={second_detail}
              third_detail={third_detail}
              button_link = {button_link}
              button_function = {button_function}
              enter_animation_values = {enter_animation_values}         
              animate_animation_values = {animate_animation_values}
              exit_animation_values = {exit_animation_values}
              product_index = {product_index}
  
          
          />
          
      </motion.div>

    
    
    
    }
 </AnimatePresence>
  )
}

export default SubscriptionCardVariant