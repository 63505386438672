import React, { useContext, useEffect, useState } from 'react'
import './ThankYouHero.css'
import MainButton from '../../UI/atoms/MainButton/MainButton'
import MainArrowText from '../../UI/atoms/MainArrowText/MainArrowText'
import MainImage from '../../UI/atoms/MainImage/MainImage'
import parent_child_forest from '../../../image_assets/father-2770301_1920.jpg'; 
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
import handleClickScroll from '../../../utils/handleClickScroll';
import { GlobalContext } from '../../../contexts/GlobalContext'

const ThankYouHero = ({
  log_state = null,
  subscribed_state,
  header_text, 
  paragraph_text,
  accent_arrow_text,
  button_text,
}) => {

  const { scrolltoSubscriptions, setScrolltoSubscriptions } = useContext(GlobalContext);


useEffect(() => {
  if (scrolltoSubscriptions) {
    handleClickScroll("subscription_section_contents")
    setScrolltoSubscriptions(false)
  }
}, [scrolltoSubscriptions, setScrolltoSubscriptions])

  return (
    <div className='thank_you_hero'>
      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='thank_you_hero_contents'>
        <div className='thank_you_hero_container'>
            <div className='left_items'>
            <h1 className='main_heading_lfg'>{header_text}</h1>
            <p className='main_paragraph'>{paragraph_text}</p>
            {/* {subscribed_state? 
            
            
            <MainButton button_link = {"./decide"} button_text = {button_text}/>
            : 
            
            
            <MainButton button_function={() => handleClickScroll("subscription_section_contents")} button_text = {button_text}/>
            
            
            } */}
            <MainButton button_link = {"/decide"} button_text = {button_text}/>
            </div>
            <div className='arrow_text_overlay'>
            <MainArrowText default_text={accent_arrow_text}/>
            </div>

        </div>
       
        </div>
    </div>
  )
}

export default ThankYouHero