import React from 'react'
import './FaqHero.css'
import Accordion from '../../UI/molecules/Accordion/Accordion'
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
const FaqHero = ({header_text = "Frequently Asked Questions"}) => {
  return (
    <div className='faq_hero_parent'>
      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='faq_hero_contents'>
        <h1 className='faq_header'>
        {header_text.split(" ").slice(0, header_text.split(" ").length-1).join(" ")}
          <span> {header_text.split(" ").slice(-1)[0]}</span>
        </h1>
      
        <div className='lower_items'>

        <div className='faq_sugggestions_parent'>

        <Accordion/>
        </div>
        
        </div>     
        </div>   
    </div>
  )
}

export default FaqHero