import React from 'react'
// import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '../components/pages/HomePage/HomePage';
import DecisionPage from '../components/pages/DecisionPage/DecisionPage';
import FaqPage from '../components/pages/FaqPage/FaqPage';
import './AppRoutes.css'
import {useState , useEffect} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import MobileMenuOverlay from '../components/UI/molecules/MobileMenuOverlay/MobileMenuOverlay';
import SignInPage from '../components/pages/SignInPage/SignInPage';
import ResultsPage from '../components/pages/ResultsPage/ResultsPage';
import ProtectedRoute from './ProtectedRoute';
import LoadingOverlay from '../components/UI/molecules/LoadingOverlay/LoadingOverlay';
import ContactOverlay from '../components/UI/molecules/ContactOverlay/ContactOverlay';
import LocalPage from '../components/pages/LocalPage/LocalPage';
import ThankYouPage from '../components/pages/ThankYouPage/ThankYouPage';
import DemoVideoOverlay from '../components/templates/DemoVideoOverlay/DemoVideoOverlay';
import SinglePlanFlowPage from '../components/pages/SinglePlanFlowPage/SinglePlanFlowPage';
import { usePostHog } from 'posthog-js/react'
import TryDecisionPage from '../components/pages/TryDecisionPage/TryDecisionPage';
import TryResultsPage from '../components/pages/TryResultsPage/TryResultsPage';

const AppRoutes = () => {
    const location = useLocation();
    const [isClicked, setIsClicked] = useState(false);
    const [toggleValue, setToggleValue] = useState("monthly");
    const [keyValue,setKeyValue] = useState(0);
    const [annualToggleClicked,setAnnualToggleClicked] = useState(true);
    const [user, setUser] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(null);
    const [scrolltoSubscriptions, setScrolltoSubscriptions] = useState(false)
    const [needsSubscription, setNeedsSubscription] = useState(false);

    //Global variables for decisions
    const [decSearchQuery, setDecSearchQuery] = useState(null);
    const [isDecResultsLoading, setIsDecResultsLoading] = useState(null);
    const [isDecDropdownActive, setIsDecDropdownActive] = useState(null);
    const [decLoadingMessage, setDecLoadingMessage] = useState(null);
    const [decSearchResults, setDecSearchResults] = useState(null);
    const [decReferencesList, setDecReferencesList] = useState(null)
    const [decisionModelMode, setDecisionModelMode] = useState("one-answer-model")
    
    const [contactOpen, setContactOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [heroButtonClicked, setHeroButtonClicked] = useState(false)
    const [videoOpen, setVideoOpen] = useState(false)
    const [generalLoading, setGeneralLoading] = useState(false)

    const posthog = usePostHog()
    
    React.useEffect(() => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
    }, []);
    
    //choose the screen size 
    const handleResize = () => {
    if (window.innerWidth < 1200) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
    }
    
    // // create an event listener
    // useEffect(() => {

    // })

    useEffect(() => {
      window.addEventListener("resize", handleResize)

      if (user) {
          posthog?.identify(user, {
              email: user.email,
          })
          posthog?.group('user', user.uid)
      }
  }, [posthog, user])

    return (
      <GlobalContext.Provider
      value={{
        isClicked,
        setIsClicked,
        toggleValue, 
        setToggleValue,
        keyValue, 
        setKeyValue,
        annualToggleClicked,
        setAnnualToggleClicked,
        user,
        setUser,
        isSubscribed,
        setIsSubscribed,
        decSearchQuery,
        setDecSearchQuery,
        isDecResultsLoading,
        setIsDecResultsLoading,
        isDecDropdownActive,
        setIsDecDropdownActive,
        decLoadingMessage, 
        setDecLoadingMessage,
        decSearchResults,
        setDecSearchResults,
        decReferencesList,
        setDecReferencesList,
        contactOpen, 
        setContactOpen,
        scrolltoSubscriptions,
        setScrolltoSubscriptions,
        needsSubscription,
        setNeedsSubscription,
        decisionModelMode,
        setDecisionModelMode,
        heroButtonClicked, 
        setHeroButtonClicked,
        videoOpen, 
        setVideoOpen,
        generalLoading, 
        setGeneralLoading
      }}
      className="App">
      <div className='App'>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path='/local' element={<LocalPage />}></Route>
            <Route path="/about" element={<ResultsPage />} />
            <Route path="/results" element={
              <ProtectedRoute>
                <ResultsPage />
              </ProtectedRoute>} 
            />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/FAQ" element={<FaqPage />} />

            <Route path="/try" element={<TryDecisionPage />} />
            <Route path="/tryresults" element={<TryResultsPage />} />
          
            <Route path="/free-trial" element={<SinglePlanFlowPage />} />
            <Route path="/pricing" element={<SinglePlanFlowPage />} />
            <Route path="/decide" element={
              <ProtectedRoute> 
                <DecisionPage /> 
              </ProtectedRoute>} 
            />

            <Route path="/thanks" element={
              <ProtectedRoute>
                <ThankYouPage />
              </ProtectedRoute>} 
            />

          </Routes>
          { contactOpen == true ?
          <ContactOverlay state ={contactOpen} />
          : null 
          }


          {isMobile == true? 
          <MobileMenuOverlay/> 
          : null
          }



          
      {isDecResultsLoading == true ? 
      
      <LoadingOverlay state = {isDecResultsLoading} /> :
        null   
      }
             
             {generalLoading == true ? 
      
      <LoadingOverlay state = {generalLoading} /> :
        null   
      }
   {videoOpen ?
    <DemoVideoOverlay display_state={videoOpen} state = {videoOpen}/>
    : null
    }

        </AnimatePresence>
      </div>

      </GlobalContext.Provider>
    );
}

export default AppRoutes