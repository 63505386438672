import React, { useContext } from 'react'
import { useState } from 'react'
import './ResultsHero.css'
import ExpandableDecisionSearchBox from '../../UI/atoms/ExpandableDecisionSearchBox/ExpandableDecisionSearchBox'
import DecisionSuggestionBox from '../../UI/atoms/DecisionSuggestionBox/DecisionSuggestionBox'
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
import ResultsButton from '../../UI/atoms/ResultsButton/ResultsButton'
import MainButton from '../../UI/atoms/MainButton/MainButton'
import { RiFileTextFill, RiArrowGoBackLine } from 'react-icons/ri';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { motion, AnimatePresence } from 'framer-motion'
import { AiOutlineClose } from 'react-icons/ai'
const ResultsHero = ({header_text = "The decision for", search_name = "the best baby stroller"}) => {
  const { 
    decSearchQuery, setDecSearchQuery, 
    isResultsLoading, setIsResultsLoading, 
    isDropdownActive, setIsDropdownActive, 
    message, setMessage,
    searchResults, setSearchResults, 
    referencesList, setReferencesList,
    decReferencesList, setDecReferencesList,
    decisionModelMode, setDecisionModelMode,  
  } = useContext(GlobalContext);

const capitalizedQuery = decSearchQuery ? decSearchQuery.charAt(0).toUpperCase() + decSearchQuery.slice(1) : "your query";
  search_name = capitalizedQuery;
  const [modalState, setModalState] = useState(false)

  // create function to execute python script
  function DirectLinkButton () {

  }

  return (
    <div className='results_hero_parent'>
      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='results_hero_contents'>
          <div className='results_top_items'>
          <h1 className='results_header'>
        {/* {header_text.split(" ").slice(0, header_text.split(" ").length-1).join(" ")}
          <span> {header_text.split(" ").slice(-1)[0]}</span> */}
          {header_text}
        </h1>
        <h2 className='results_subtext'>{search_name}</h2>
          </div>

        
        <div className='lower_items'>
        <ExpandableDecisionSearchBox/>
        {decisionModelMode == "one-answer-model" ? 
        
        <ResultsButton button_text='Take Me There' button_icon={<RiFileTextFill className='button_icon'/>} button_function = {()=>{DirectLinkButton()}}/>
        
        : 
        
        <ResultsButton button_text='References' button_icon={<RiFileTextFill className='button_icon'/>} button_function = {()=>{setModalState(true)}}/>
        
        }
          
          <AnimatePresence>
      
          { modalState == true?

          
          <motion.div className={modalState == true?'references_modal': 'references_modal_default'}
          
          key = {"references_modal_parent"}
          initial={{ translateY: 15, opacity: 0 }}
          animate={{ translateY:0, opacity: 1 }}
          exit={{
            translateY:-15,
            opacity: 0,
            transition: { type: 'ease'},
          }}
          transition={{ delay: .1 , duration:.3}}          
          
          
          
          
          >
          <div className = "references_modal_top_items">
            <button className='references_modal_close_button' onClick={()=>{setModalState(false)}}>
              <AiOutlineClose className='ref_button_icon'/>
            </button>
          <h1 className='results_header'>
            Reference Links for
          </h1>
          <h2 className='results_subtext'>{search_name}</h2>
          </div>
        {decReferencesList?.map((reference_link) => (
          <div className='reference_link_container'>
            <a href = {reference_link}>
            <p className="reference_link">{reference_link}</p>
            </a>
          </div>
         ))}    

        </motion.div>
      
        :
        null
        
        }
  </AnimatePresence>
          <MainButton button_link={"/decide"} button_text='Try Another Decision' button_icon={<RiArrowGoBackLine className='button_icon'/>}/>
   

        <div className='results_sugggestions_parent'>

        </div>
        
        </div>    
         
        </div>   
        
    </div>
  )
}

export default ResultsHero