import React from 'react'
import './ContactOverlay.css'
import MainCloseButton from '../../atoms/MainCloseButton/MainCloseButton'
import blur_circle_1 from '../../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../../image_assets/Blur_Circle_5.webp'; 
import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext';
const ContactOverlay = ({state}) => {
  const {contactOpen, setContactOpen} = useContext(GlobalContext);
  function contactStateChange(){
    if(contactOpen == true){
        setContactOpen(false)
    } else {
        setContactOpen(true)
    }
}
  return (


    <AnimatePresence mode='wait'>
        { contactOpen == true ? (
    <motion.div className={contactOpen == true?'contact_overlay_parent': 'contact_overlay_parent_default'}
    key = {"contact_overlay_parent"}
    initial={{ translateY: 15, opacity: 0 }}
    animate={{ translateY:0, opacity: 1 }}
    exit={{
      translateY:-15,
      opacity: 0,
      transition: { type: 'ease' },
    }}
    transition={{ delay: .2 , duration:.3}}
    
    >
        
        <img className = "blur_circle_1" src = {blur_circle_1}/>
        <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='contact_overlay_contents'>

        <MainCloseButton state = {contactOpen} button_function = {()=>{contactStateChange()}}/>

        
        <div className='contact_content_details'>
        <a href="mailto:collin@1-decision.com">
            <p className='email_data'>collin@1-decision.com</p>
        </a>
            
        </div>

        </div>

    </motion.div>
    ) : null}
    </AnimatePresence>
  )
}

export default ContactOverlay