import React from 'react'
import './QuestionsSection.css'
import  blur_triangles from '../../../image_assets/Blur_Triangles.webp'
import { Link } from 'react-router-dom'
const QuestionsSection = () => {
  return (
    <div className='questions_section_parent'>
        <img src = {blur_triangles} className = "questions_background_element"/>
        <div className='text_items'>
            <h1>Questions?</h1>
        <h2>View our 
        <Link to = "/FAQ" >
          <span> FAQ Page</span> 
        </Link>
         
            </h2>
        </div>
        </div>
  )
}

export default QuestionsSection