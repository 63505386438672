import { useNavigate } from 'react-router-dom';
import handleSignOut from '../../../../utils/handleSignOut'
import './NavButton.css'

const SignOutButton = () => {

  const navigate = useNavigate();

    return (
    <button className='nav_button' onClick={() => handleSignOut(navigate)} type="button">
        <div>Sign out</div>
    </button>
    )
}

export default SignOutButton