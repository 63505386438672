import React, { useEffect } from 'react'
import './NavBar.css'
import black_logo from '../../../../image_assets/black-logo.png'; 
import NavButton from '../../atoms/NavButton/NavButton'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import SignOutButton from '../../atoms/NavButton/SignOutButton';
import IsLoggedin from '../../../../utils/IsLoggedin';
import DecisionButton from '../../atoms/DecisionButton/DecisionButton';
const NavBar = () => {
  const [active, setActive] = useState(false);
  const {isClicked, setIsClicked, user, setUser, isSubscribed, setIsSubscribed, contactOpen, setContactOpen } = useContext(GlobalContext);

  function stateAnimations(){
    
    if (active == false){
      setActive(true) } 
    else {setActive(false)}
    if (isClicked == false){
      setIsClicked(true)}else{ setIsClicked(false)}
    } 

    useEffect(() => {
      IsLoggedin(setUser, setIsSubscribed);
    }, []);
    function contactStateChange(){
      if(contactOpen == true){
          setContactOpen(false)
      } else {
  
          setContactOpen(true)
      }
  }
  
  return (
    <nav className='navbar_parent'>
        <div className='navbar_items'>
        <Link to = "/">
        <img  className = "logo" src={black_logo} alt="1-Decision Logo" onClick={()=>{window.localStorage.setItem('1-DECISION-APP', false)}}/>
        </Link>
        <div className='button_array'>
        <Link to = "/" >
          <NavButton button_style='home_button_style' button_function={()=>{window.localStorage.setItem('1-DECISION-APP', false)}}/>
        </Link>
         
        <Link to = "/FAQ" >
          <NavButton button_text= 'FAQ'/>
        </Link>
        <Link to = "/pricing" >
          <NavButton button_text= 'Pricing'/>
        </Link>
        <Link to = "https://emailcontact.1-decision.com/" >
          <NavButton button_text= 'Contact Us' />
        </Link>
          

           {isSubscribed ? (

            <DecisionButton button_text= 'Decide' button_link={"/decide"}/>
       

          ) :
          (
            <Link to = "https://about.1-decision.com/" >
              <NavButton button_text= 'About Us'/>
            </Link>
  
          )
        }
        {user ? (
            <Link to = "https://billing.stripe.com/p/login/4gw3eh0tQ0Kk5R68ww">
            <NavButton button_text='My Account'/>
          </Link>
          ) :
          (
            null
          )
        }  

          {user ? (
            <Link>
               <SignOutButton />
            </Link>
          ) :
          (
            <Link to = "/signin">
              <NavButton button_text='Sign Up/Sign In' button_function={()=>{window.localStorage.setItem('1-DECISION-APP', false)}}/>
            </Link>
          )
        }
  
       
        </div>
        
        <div className={active? 'mobile_menu_parent': 'mobile_menu_parent_default'} onClick={() => {stateAnimations()}}>
          <ul className='mobile_menu_bars_list'>
            <li className={active == false ?  'top_bar': 'top_bar_clicked'}/>
            <li className={active == false ? 'middle_bar': 'middle_bar_clicked'}/>
            <li className={active == false ? 'bottom_bar': 'bottom_bar_clicked'}/>
          </ul>
        </div>
        </div>
     <div className='overlay_test'>
      
     </div>
    </nav>
  )
}

export default NavBar