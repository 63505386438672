import React from 'react'
import './NavButton.css'

import { RiArrowRightFill } from 'react-icons/ri'
const NavButton = ({button_text = "Home", button_style = 'nav_button', button_function = null}) => {
  return (
    <button onClick={button_function} className={button_style}>
        {button_text}
    </button>
  )
}

export default NavButton