import React from 'react'
import './TextBoxWithScribble.css'
import TextBox from '../../atoms/TextBox/TextBox'
import {ReactComponent as TextBoxScribble} from '../../../../svg_assets/Main_scribble.svg';
const TextBoxWithScribble = () => {
  return (
    <div className='tbws_parent'>
        <TextBox/>
        <TextBoxScribble/>
    </div>
  )
}

export default TextBoxWithScribble