import React from 'react'
import './StatsSection.css'
import StatsBox from '../../UI/atoms/StatsBox/StatsBox'
const StatsSection = () => {
  return (
    <div className='stats_parent'>
        <div className='stats_contents'>
        <h1 className='stats_main_text'>See The Difference</h1>
        <div className='stats_box_array'>
        <StatsBox number_value='-49%' default_text='Stress'/>
        <StatsBox number_value='2.6' default_text='Days Gained/month'/>
        <StatsBox number_value='3,900+' default_text='Ads Vanished Daily'/>
        </div>
        </div>
    </div>
  )
}

export default StatsSection