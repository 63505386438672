const data = {
    enter_values: 
      {
        translateY: -20,
        translateX: 190,
        rotateZ: "-20deg",
        originY: 1,
        scaleX: 1, 
        opacity: 0 , 
      },
  
    animate_values: 
        {
          translateY: 0,
          translateX: 0,
          rotateZ: 0,
          originY: 1,
          originX: 0,
          opacity: 1, 
        },
    
      exit_values: 
        {
            scaleX: 0.97,
            translateY: 50,
            opacity: 0,
            transition: { type: 'linear'},
            translateY: -20,
            translateX: 190,
            rotateZ: "-20deg",
            originY: 1,
            originX: 0
            
        },
     
      reverse_enter_values: 
        {
          translateY: -20, 
          translateX: 190, 
          scaleX: 1, 
          opacity: 0 , 
          rotateZ: "20deg", 
          originY: -2
        },
        reverse_animate_values: 
        {
          translateY: 0, 
          translateX: 0, 
          scaleX: 1, 
          opacity: 1, 
          rotateZ: "0deg", 
          originY: -1,  
          originX: 1
        },
        reverse_exit_values: 
        {
          scaleX: 0.97,
          translateY: 50,
          opacity: 0,
          transition: { type: 'linear', stiffness: 50 },
          translateY: -20,
          translateX: 190,
          rotateZ: "20deg",
          originY: -2,
          originX: 0
        },



        middle_enter_values: 
        {
            scaleX: 0.97,
            translateY: -50,
            opacity: 0,
            transition: { type: 'linear', stiffness: 50 },
            translateY: -20,
            originY: 1,
            originX: 0
        },
        middle_exit_values: 
        {
            scaleX: 0.97,
            translateY: 50,
            opacity: 0,
            transition: { type: 'linear', stiffness: 50 },
            translateY: -20,
            originY: 1,
            originX: 0
        },
  };
//   exit={{
//     scaleX: 0.97,
//     translateY: 50,
//     opacity: 0,
//     transition: { type: 'linear', stiffness: 50 },
//     translateY: animation_array[0],
//     translateX: animation_array[1],
//     rotateZ: animation_array[2].toString() +"deg",
//     originY: animation_array[3],
//     originX: 0
//   }}
  export default data;
  