const productsYearly = [
  {
    name: "Lite",
    price: 35.0,
    description: "Limited features compared to core plans",
    subscriptionDetails: [
      "No Free Trial",
      "No Personalization",
      "15 Decisions / mo",
    ],
    apiId: "price_1MgaZUDa6qlpCRXeNQoHYcGx",
    productId: "prod_MmbTm21doXReuB",
    directLink: "",
  },
  {
    name: "Single",
    price: 99.0,
    description: "Comes with a 9-day free trial and more",
    subscriptionDetails: [
      "9-day Free Trial",
      "Money-back Guarantee",
      "Unlimited Decisons",
    ],
    apiId: "price_1M32HWDa6qlpCRXeruhhtX3D",
    productId: "prod_MmbUvWdQc3zXIM",
    directLink: "",
  },
  {
    name: "Family",
    price: 218.0,
    description: "Perfect for families to share. Unlimited Decisions",
    subscriptionDetails: [
      "Multiple Profiles",
      "Money-back Guarantee",
      "Unlimited Decisons",
    ],
    apiId: "price_1M32GeDa6qlpCRXeA3kjJJHA",
    productId: "prod_MmbTleo4UbvjLX",
    directLink: "",
  },
];

export default productsYearly;
