import React from 'react'
import './FooterBanner.css'
import MainButton from '../../atoms/MainButton/MainButton'
import {ReactComponent as FooterBannerScribble} from '../../../../svg_assets/Banner_Scribble.svg';
import handleClickScroll from '../../../../utils/handleClickScroll';
const FooterBanner = () => {
  return (
    <div className='footer_banner_parent'>
        <div className='footer_banner_contents'>
            <div className='left_items'>
            <h1 className='footer_banner_header'>
            Ready for THE simple answer?
            </h1>
            <h2 className='footer_banner_subtext'>
            Let’s get started!
            </h2>          
            <FooterBannerScribble className = "footer_banner_scribble"/>

            </div>
            <div className='right_items'>
                <MainButton button_function={() => handleClickScroll("subscription_section_contents")}/>
            </div>

        </div>
    </div>
  )
}

export default FooterBanner