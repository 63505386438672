import React from 'react'
import './VideoPlayer.css'
import { RiPlayCircleFill } from 'react-icons/ri'
import video_preview from '../../../../image_assets/video_preview.png'
import { useContext } from 'react'
import { GlobalContext } from '../../../../contexts/GlobalContext';

const VideoPlayer = () => {
    const {videoOpen, setVideoOpen} = useContext(GlobalContext);
  return (
    <div className='video_player_container'>
        <div className= "video_background">
            <img src = {video_preview} className='video_preview_image'></img>

        </div>
        <RiPlayCircleFill className='play_button_icon' onClick={()=>{setVideoOpen(true)}}/>


    </div>
  )
}

export default VideoPlayer