import React, { useContext, useEffect, useState } from 'react'
import './HomeHero.css'
import MainButton from '../../UI/atoms/MainButton/MainButton'
import AccentButton from '../../UI/atoms/AccentButton/AccentButton'
import MainArrowText from '../../UI/atoms/MainArrowText/MainArrowText'
import MainImage from '../../UI/atoms/MainImage/MainImage'
import parent_child_forest from '../../../image_assets/father-2770301_1920.jpg'; 
import blur_circle_1 from '../../../image_assets/Blur_Circle_4.webp'; 
import blur_circle_2 from '../../../image_assets/Blur_Circle_5.webp'; 
import handleClickScroll from '../../../utils/handleClickScroll';
import productsMonthly from '../../../ProductsMonthly.js';
import { GlobalContext } from '../../../contexts/GlobalContext'
import { subscribe } from "../../../firebaseConfig/firebase";
import { Navigate } from 'react-router-dom';
import VideoPlayer from '../../UI/molecules/VideoPlayer/VideoPlayer'
const HomeHero = ({
  log_state = null,
  subscribed_state,
  header_text, 
  paragraph_text,
  accent_arrow_text,
  button_text,
}) => {

  const { scrolltoSubscriptions, setScrolltoSubscriptions, heroButtonClicked, setHeroButtonClicked, isSubscribed, setIsSubscribed, isAuthChecked} = useContext(GlobalContext);

  const products = productsMonthly 
// window.onload()
//----------STRIPE DATA RETRIEVAL TEST----------------------\\

///retrieve stripe data
const [stripeData, setStripeData] = useState([0])
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch('https://api.npms.io/v2/search?q=react')
        .then(response => response.json())
        .then(data => setStripeData(data));
  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  

const { user, videoOpen, setVideoOpen } = useContext(GlobalContext);
const [loading, setLoading] = useState(false);

const handleSubscriptionButtonClick = async (apiId) => {

  if (!user) {
    // User is not logged in, redirect to sign-in page
    setHeroButtonClicked(true)
    console.log("initieis", heroButtonClicked)
    // window.location.href = window.location.href + "signin";

    return;
  } 

  setLoading(true);

  try {
    subscribe(user, apiId);
  } catch (error) {
    console.error(error);
  }

  setLoading(false);
};


function heroButtonFlow (pass_id){

    console.log("state log test", heroButtonClicked);
    setHeroButtonClicked(true)


    window.location.href = "/signin";
    if (isSubscribed == false) {
      window.localStorage.setItem('1-DECISION-APP', JSON.stringify(heroButtonClicked))
    } else {
      window.localStorage.setItem('1-DECISION-APP', 'false')
      console.log("user")
    }
     
   

    // localStorage.setItem("user", {"name": "abc"});
 

    // handleSubscriptionButtonClick(products[1].apiId)


    try {
      subscribe(user, pass_id);
    } catch (error) {
      console.error(error);
    }
}

useEffect(() => {
  const data = window.localStorage.getItem('1-DECISION-APP');
  if ( data !== null ) setHeroButtonClicked(JSON.parse(data));
}, []);

useEffect(() => {
  console.log('heroButtonClicked', heroButtonClicked)
  window.localStorage.setItem('1-DECISION-APP', JSON.stringify(heroButtonClicked));
}, [heroButtonClicked])




//----------STRIPE DATA RETRIEVAL TEST----------------------\\





useEffect(() => {
  if (scrolltoSubscriptions) {
    handleClickScroll("subscription_section_contents")
    setScrolltoSubscriptions(false)
    
  }
}, [scrolltoSubscriptions, setScrolltoSubscriptions])

  return (
    <div className='home_hero'>
      <img className = "blur_circle_1" src = {blur_circle_1}/>
      <img className = "blur_circle_2" src = {blur_circle_2}/>
        <div className='home_hero_contents'>
        <div className='home_hero_container'>
            <div className='left_items'>
            <h1 className='main_heading_lfg'>{header_text}</h1>
            <p className='main_paragraph'>{paragraph_text}</p>
            {subscribed_state? 
            
            
            <MainButton button_link = {"./decide"} button_text = {button_text}/>
            : 
              <div className='buttons_container'>
            <MainButton button_link = {null} button_function = {()=>{heroButtonFlow()}} button_text = {button_text}/>
            <AccentButton button_link = {null} button_function = {()=>{setVideoOpen(true);console.log("clicky")}} button_text = {"DEMO"}/>
</div>



            
            }
            {/* {user && heroButtonClicked == true ? 
                  <Navigate to="/signin" />
                  :
                  null
            } */}
                    
            {/* <h3 className={ user ? 'demo_open_text_mod' : 'demo_open_text' } onClick={()=>{setVideoOpen(true)}}>See a Demo of how it works</h3> */}
            {/* <button onClick={()=>{console.log(user); console.log("check if user has a plan", isSubscribed)}}>TEST STATE</button> */}
            </div>
            <div className='arrow_text_overlay'>
            <MainArrowText default_text={accent_arrow_text}/>
            </div>
            <div className='right_items'>
              {/* <div className='images_container'>
              <MainImage/>
              <MainImage default_image={parent_child_forest}/>
              </div> */}
              <VideoPlayer/>
            </div>
        </div>
       
        </div>
    </div>
  )
}

export default HomeHero