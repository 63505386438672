import React, { useContext } from 'react'
import './DecisionButton.css'
import { Link } from 'react-router-dom'
import { RiSearch2Line } from 'react-icons/ri'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { useRef } from 'react'
const DecisionButton = ({
  button_text = "Start Free Trial",
  button_icon = <RiSearch2Line className='button_icon'/>, 
  button_function, 
  button_link = null, 
  button_id = "decision_button",
  decisionButtonRef


}) => {
  const { decSearchQuery, setDecSearchQuery } = useContext(GlobalContext);
  
  // button_function = () => {
  //   console.log(decSearchQuery)
  // }
  return (
    <Link to = {button_link}> 
      <button ref={decisionButtonRef} onClick={button_function} className= {button_icon != "" ? "decision_button" : "decision_button_no_icon"}>

      {button_icon}
      {button_text}
      </button>  
    </Link>

  )
}

export default DecisionButton