import React, { useState } from 'react'
import './AnswerModelButton.css'
import {BiRightTopArrowCircle} from 'react-icons/bi/'
import {TbSwitch} from 'react-icons/tb/'

const AnswerModelButton = ({button_function = null, state_drill = 'one-answer-model'}) => {
const [active, setActive] = useState(false)
  return (
    <button className={state_drill != 'one-answer-model'? 'answer_model_button' : 'answer_model_button_secondary'} onClick={button_function}>
        <TbSwitch className = {state_drill == 'one-answer-model'? 'answer_model_button_icon' : 'answer_model_button_secondary_icon'} />
    </button>
  )
}

export default AnswerModelButton