import React from 'react'
import './TextBox.css'
const TextBox = ({number_value = "Feeling Overwhelmed?", default_text = "Make Life Easier."}) => {
  return (
    <div className='texts_box'>
        <h1 className='texts_header'>
        {number_value}
        </h1>
        <h2 className='texts_subtext'>
        {default_text.split(" ").slice(0, default_text.split(" ").length-1).join(" ")}
          <span> {default_text.split(" ").slice(-1)[0]}</span>
        </h2>
        
    </div>
  )
}

export default TextBox