import React from 'react'
import {ReactComponent as HeroArrow} from '../../../../svg_assets/Hero_Arrow_Updated.svg';
import './MainArrowText.css'
const MainArrowText = ({default_text = "Try Us Now!"}) => {
  return (
    <div className='arrow_text_container'>
        <h3 className='accent_text'>{default_text}</h3>
        <div className='arrow_element'>
        <HeroArrow/>
        </div>


    </div>
  )
}

export default MainArrowText