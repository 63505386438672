import React from 'react'
import './Tooltip.css'
import {RiInformationLine} from 'react-icons/ri/'
import { useState } from 'react'

const Tooltip = () => {
    const [open, setOpen] = useState(false);

    // function modalOpen() {
    //     setOpen(!open);
    // }

    return (
        <div className='tooltip_container'>
            <RiInformationLine
                className='tooltip_icon'
                // onClick={modalOpen}
                onMouseOver={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            />
            {open === false ? null : (
                <div
                    className={
                        open === false
                            ? 'tooltip_modal_hidden'
                            : 'tooltip_modal'
                    }
                    onMouseOver={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                >
                    <div className='tooltip_modal_contents'>
                        <h1>There are Two Modes:</h1>
                        <div className='top_items'>
                            <h2>One Answer</h2>
                            <p>Configures search to find the most concise answer.</p>
                        </div>
                        <div className='bottom_items'>
                            <h2>Few Answers</h2>
                            <p>Configures search to find the multiple top options.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
