import React from 'react'
import './ConditionalSignInComponent.css'
import SignedInScreenHero from '../SignedInScreenHero/SignedInScreenHero'
import SinglePlanFlowHero from '../SinglePlanFlowHero/SinglePlanFlowHero'
import { motion } from 'framer-motion'
const ConditionalSignInComponent = ({displayMode = 0, sign_in_element = null}) => {
  return (
    <motion.div className='fr_navigation_cd'>
    { displayMode <= 1 ? null : displayMode == 2? <SignedInScreenHero/>: displayMode == 3  ? <SinglePlanFlowHero/>: null}
        
    {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> */}
    </motion.div>
  )
}

export default ConditionalSignInComponent