import { firebase, subscribe } from "../firebaseConfig/firebase";
import { startDataListeners } from "../firebaseConfig/firebase";

const IsLoggedin = (setUser, setIsSubscribed, shouldSubscribe, apiId) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            startDataListeners(user, setIsSubscribed);
            setUser(user);

            if (shouldSubscribe && apiId) {
              subscribe(user, apiId); // call the subscribe function if shouldSubscribe is true
            }
          } else {
            setUser(null);
            setIsSubscribed(false);
          }
          resolve(); // resolve the promise once the user's authentication state is checked
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error(error);
        reject(error); // reject the promise if there is an error
      });
  });
};

export default IsLoggedin;
