import React from 'react'
import './FooterList.css'

const FooterList = ({list_logo = "", list_header = "Company", list_items = <li>Default</li>}) => {
  return (
    <div className='footer_list_parent'>

    {typeof list_header == 'string' ? 
    <h1 className='footer_list_header'>
    {list_header}
    </h1> 
    : 
    <img src = 
    {list_logo}
     className = "list_logo"/>}
        <div className='footer_list_ul'>
            {list_items}
        </div>
    </div>
  )
}

export default FooterList